import {useEffect } from "react"
import { ToastContainer } from '@intraversa-lab/styleguide'
import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter } from 'react-router-dom'
import { Routes } from './routes/Routes'

import { AuthProvider } from './services'

const queryClient = new QueryClient()

function App(props) {
  useEffect(() => {
    return () => {
      queryClient.clear();
    };
  }, [])

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <AuthProvider>
          <Routes />
          <ToastContainer />
        </AuthProvider>
      </BrowserRouter>
    </QueryClientProvider>
  )
}

export default App
