import { TextField, TextFieldProps } from '@intraversa-lab/styleguide'
import { Box } from '@mui/material'
import { Controller, UseControllerProps, FieldValues } from 'react-hook-form'

export function TextFieldRHF<FormType extends FieldValues>({
  name,
  rules,
  control,
  showError = true,
  ...textFieldParams
}: {
  name: string,
  showError?: boolean
} & TextFieldProps &
  UseControllerProps<FormType>) {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <Box>
          <TextField
            helperText={showError ? error?.message : ''}
            error={!!error?.message}
            value={value}
            onChange={(value) => onChange(value)}
            {...textFieldParams}
          />
        </Box>
      )}
    />
  )
}
