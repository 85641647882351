import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { Button, ButtonPortico, toast } from '@intraversa-lab/styleguide'
import { Box, Stack, Typography } from '@mui/material'
import { InputToken } from 'components/inputToken/InputToken'
import { usePostConfirmationCode, usePostSendCode } from 'domains'
import { useNavigate } from 'react-router-dom'
import { hideEmail } from 'utils/hideEmail'

import { IChangePasswordStepsProps } from '../../ChangePassword'

import { Countdown } from './Countdown'

export const VerificationCode = ({
  handleNext,
  handleChangeFormData,
  formDataChangePassword,
}: IChangePasswordStepsProps) => {
  const navigate = useNavigate()
  const isFirst = useRef(false)
  const { sendCode } = usePostSendCode()
  const { isLoading: isLoadingConfirmation, confirmationCode } =
    usePostConfirmationCode({
      onSuccess: (data) => {
        handleChangeFormData('codeId', data.id)
        handleNext()
      },
      onError: () => {
        setCodeError("Código inválido")
      }
    })
  const [value, setValue] = useState('')
  const [countComplete, setCountComplete] = useState(false)
  const [restartCount, setRestartCount] = useState(false)
  const [codeError, setCodeError] = useState("")
  const [countSentCode, setCountSentCode] = useState(0)

  const isActivedButton = useMemo(() => {
    return value.length === 6
  }, [value])

  const onConfirmationCode = useCallback(() => {
    setCodeError("")
    confirmationCode({
      code: value,
      userId: formDataChangePassword.userId,
    })
  }, [formDataChangePassword, confirmationCode])

  const handleSendCode = () => {
    setRestartCount(true)
    setCountComplete(false)
    sendCode({
      userId: formDataChangePassword.userId,
    })
    toast({
      type: 'success',
      text: 'Código enviado com sucesso',
      description: `Um novo código foi enviado para o e-mail ${hideEmail(formDataChangePassword.email)}`,
    })
  }
  

  useEffect(() => {
    if (!isFirst.current) {
      isFirst.current = true

      sendCode({
        userId: formDataChangePassword.userId,
      })
    }
  }, [])

  return (
    <>
      <Box>
        <Stack spacing={2}>
          <Typography
            variant="h1"
            fontFamily={'Merriweather'}
            color={'#161F28'}
            fontSize={'36px'}>
            Código de Verificação
          </Typography>
          <Typography
            id="CodeSentMessage"
            variant="h6"
            fontFamily={'Roboto'}
            color={'#75808A'}
            style={{ marginTop: '24px' }}>
            Insira o código enviado para o email{' '}
            {hideEmail(formDataChangePassword.email)}
          </Typography>
          <Box
            display="flex"
            justifyContent={'center'}
            width={'100%'}
            style={{ marginTop: '24px' }}>
            <InputToken numDigits={6} setValues={(v: string) => setValue(v)} error={!!codeError} />
          </Box>
          {codeError && (
            <p
              style={{
                color: '#FF4B38',
                fontSize: '12px',
                marginTop: '10px',
              }}>
              {codeError}
            </p>
          )}
          <Box
            display={'flex'}
            alignItems={'center'}
            alignSelf={'center'}
            style={{ marginTop: '24px' }}
            gap={'32px'}>
            {countComplete ? (
              <div
                style={{
                  display: 'flex',
                  gap: '8px',
                  flexWrap: 'wrap',
                  alignItems: 'center',
                }}>
                <p style={{ color: '#75808A', fontWeight: '400', margin: '0' }}>
                  Não recebeu o código?
                </p>

                <Button
                  id="ClickToResend"
                  variant="text"
                  arround
                  style={{
                    margin: '0',
                    fontWeight: '500',
                    fontFamily: 'Roboto',
                    textTransform: 'none',
                    padding: '12px 24px',
                    fontSize: '16px',
                  }}
                  sx={{
                    '&:hover': {
                      backgroundColor: '#E2D9F9 !important',
                      color: '#5735B4',
                    },
                  }}
                  onClick={handleSendCode}>
                  Clique para reenviar
                </Button>
              </div>
            ) : (
              <p style={{ color: '#75808A', fontWeight: '400' }}>
                Enviar novamente em{' '}
                <Countdown
                  start={60}
                  restartCount={restartCount}
                  setCountComplete={(value) => {
                    setCountComplete(value)
                    setRestartCount(false)
                  }}
                />
                s
              </p>
            )}
          </Box>
          <ButtonPortico
            onClick={onConfirmationCode}
            disabled={!isActivedButton}
          >
            Confirmar
          </ButtonPortico>
        </Stack>

        <Box display={'flex'} justifyContent={'center'}>
          <Button
            variant="text"
            arround
            style={{
              marginTop: 48,
              textTransform: 'none',
              padding: '12px 24px',
              fontSize: '16px',
            }}
            sx={{
              '&:hover': {
                backgroundColor: '#E2D9F9 !important',
                color: '#5735B4',

              },
            }}
            onClick={() => navigate('/')}>
            Retornar ao Login
          </Button>
        </Box>
      </Box>
    </>
  )
}
