import React, {
  ChangeEvent,
  FocusEvent,
  KeyboardEvent,
  useEffect,
  useRef,
  useState,
} from 'react'

import * as S from './styles'
import { Grid, Typography, useMediaQuery } from '@mui/material'
import { makeStyles } from '@mui/styles'
import PropTypes from 'prop-types'

interface InputTokenProps {
  numDigits: number
  error?: boolean
  setValues: (code: string) => void
}

const useStyles = makeStyles((theme: any) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    '& p': {
      fontSize: 24,
      fontWeight: 600,
      color: '#73757D',
      marginRight: '4px',
    },

    '& label': {
      marginRight: 4,
    },

    '& input': {
      width: '60px',
      height: '80px',
      background: '#ffffff',
      border: '1px solid #73757d',
      borderRadius: '16px',
      textAlign: 'center',
      marginRight: '15.6px',

      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '32px',
      lineHeight: '22px',

      color: '#000000',

      '&:focus': {
        outline: 'none',
        borderColor: '#6D42E1',
      },

      '&:last-child': {
        marginRight: 0,
      },

      [theme.breakpoints.down('sm')]: {
        width: '50px',
        height: '80px',
      },
    },
  },
}))

export const InputToken: React.FC<InputTokenProps> = ({
  numDigits,
  setValues,
  error
}) => {
  const inputRefs = useRef<HTMLInputElement[]>([])
  const classes = useStyles()
  const isSmallScreen = useMediaQuery('(max-width:450px)')

  const updateValues = () => {
    const code = inputRefs.current
      .map((input) => input?.value)
      .filter(Boolean)
      .join('')
    setValues(code)
  }

  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const value = event.target.value
    if (!/^[0-9]*$/.test(value)) {
      event.target.value = value.replace(/[^0-9]/g, '')
    }

    const nextIndex = index + 1
    if (nextIndex < numDigits && event.target.value) {
      inputRefs.current[nextIndex].focus()
    }
    updateValues()
  }

  const handleInputFocus = (event: FocusEvent<HTMLInputElement>) => {
    event.target.select()
  }

  const handleInputKeyDown = (
    event: KeyboardEvent<HTMLInputElement>,
    index: number,
  ) => {
    if (event.key === 'Backspace' && !event.currentTarget.value) {
      const prevIndex = index - 1
      if (prevIndex >= 0) {
        inputRefs.current[prevIndex].focus()
      }
    }
  }

  const handleInputPaste = (event: any) => {
    event.preventDefault()
    const pastedText = event.clipboardData
      .getData('text')
      .replace(/[^0-9]/g, '')
    const digits = pastedText.slice(0, numDigits).split('')

    digits.forEach((char: any, index: any) => {
      if (inputRefs.current[index]) {
        inputRefs.current[index].value = char
      }
    })

    updateValues()

    const nextIndex = digits.length < numDigits ? digits.length : numDigits - 1
    inputRefs.current[nextIndex]?.focus()
  }
  
  const clear = () => {
    inputRefs.current.forEach((input) => {
      if (input) {
        input.value = ''; 
      }
    });
    setValues("")
  }
  
  useEffect(() => {
    if(error){
      clear()
    }
  }, [error])
  

  return (
    <Grid container  item xs={12} className={classes.root}>
      {Array.from({ length: numDigits }).map((_, index) => (
        <S.Wrapper key={index} error={error}>
          <label>
            <input
              type="tel"
              maxLength={1}
              onChange={(e) => handleInputChange(e, index)}
              onFocus={handleInputFocus}
              onPaste={handleInputPaste}
              onKeyDown={(e) => handleInputKeyDown(e, index)}
              ref={(el) => (inputRefs.current[index] = el!)}
              style={{ fontFamily: 'Roboto'}}
            />
          </label>

        </S.Wrapper>
      ))}
    </Grid>
  )
}
