import {useMutation} from 'react-query'
import {MutationOptions} from 'infra'
import {DefaultMessageApi} from 'types'

import {userService} from '../userService'

interface Variables {
  userId: string
}

export function usePostSendCode(options?: MutationOptions<DefaultMessageApi>) {
  const mutation = useMutation<DefaultMessageApi, Error, Variables>({
    mutationFn: (variables: Variables) => userService.postSendCode(variables),
    retry: false,
    onError: (error) => {
      if (options?.onError) {
        options.onError(error.message)
      }
    },
    onSuccess: (authCredentials) => {
      if (options?.onSuccess) {
        options.onSuccess(authCredentials)
      }
    },
  })

  return {
    isLoading: mutation.isLoading,
    sendCode: (variables: Variables) => mutation.mutate(variables),
    isSuccess: mutation.isSuccess,
    isError: mutation.isError,
  }
}
