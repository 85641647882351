import { api } from "api";
import { IAffiliation } from "./affiliationTypes";
import { endpointsAffiliation } from "infra";

async function getAFfiliation({
  companyId,
  page = 0,
  offset = 10,
  name,
  active,
  sort
}: {
  companyId: string;
  page: number;
  offset: number;
  name: string;
  active?: boolean;
  sort: string;
}): Promise<IAffiliation[]> {
  const response = await api.get(
    endpointsAffiliation.getAffiliation.replace(":companyId", companyId),
    {
      params: {
        page,
        offset,
        name,
        active,
        sort
      }
    }
  );
  return response.data;
}

async function getAffiliationByCompanyByUserId({
  companyId,
  userId
}: {
  companyId: string;
  userId: string;
}): Promise<IAffiliation> {
  const response = await api.get(
    endpointsAffiliation.getAffiliationByCompanyByUserId
      .replace(":companyId", companyId)
      .replace(":userId", userId)
  );
  return response.data;
}

export const affiliationApi = {
  getAFfiliation,
  getAffiliationByCompanyByUserId
};
