import {z} from 'zod'

export const userProfileSchema = z.object({
  nomeCompleto: z.string(),
  email: z.string().email(),
  cargo: z.string(),
})

export type UserProfileSchema = z.infer<typeof userProfileSchema>

