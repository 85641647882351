import { toast } from "@intraversa-lab/styleguide"
import { IRole } from "./roleTypes"
import { roleApi } from "./roleApi"


async function getRole({
  page = 0,
  offset = 10,
  sort
}: {

  page: number
  offset: number
  sort?: string
}): Promise<IRole[]> {
  try {
    const respose = await roleApi.getRole({
      page,
      offset,
      sort
    })

    return respose
  } catch (error) {
    toast({
      type: 'error',
      text: 'Erro ao buscar RPAs.',
      description: 'Tente novamente em instantes.',
    })
    throw new Error('Tente novamente em instantes.')
  }
}

export const roleService = {
  getRole
}