/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react'

import {COLORS} from '@intraversa-lab/styleguide'
import {
  ReportProblem,
  InfoOutlined,
  CheckCircleOutline,
} from '@mui/icons-material'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import {makeStyles} from '@mui/styles'

const modifiersType = {
  warning: {
    primary: '#FFE238',
    secondary: '#FFF3AF',
    Icon: <ReportProblem />,
  },
  error: {
    primary: '#FF891C',
    secondary: '#FFE7D2',
    Icon: <InfoOutlined />,
  },
  success: {
    primary: '#2DCF7A',
    secondary: '#AFFFD5',
    Icon: <CheckCircleOutline />,
  },
  info: {
    primary: '#3C454D',
    secondary: '#EFF2F4',
    Icon: <InfoOutlined />,
  },
}

const useStyles = makeStyles({
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  headerIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 28,
    height: 28,
    borderRadius: '50%',
    marginRight: 12,
    '&>svg': {
      color: '#fff',
    },
  },
  headerTitle: {
    padding: 0,
    margin: 0,
    fontSize: '18px',
    fontFamily: 'Roboto',
    fontWeight: 500,
    color: COLORS.shade[10],
  },
  description: {
    padding: 0,
    margin: 0,
    fontSize: '16px',
    fontFamily: 'Roboto',
    fontWeight: 400,
    marginLeft: 40,
    color: COLORS.neutral[90],
  },
  buttonCancel: {
    color: '#59636B !important',
  },
  button: {
    transition: 'all 0.2s ease',
    '&:hover': {
      filter: 'brightness(0.9)',
    },
  },
})

interface IConfirmModal {
  color:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning'
  onConfirm: Function
  onClose: (clicked?: boolean) => void
  title: string
  description: string
  cancel: string
  confirm: string
  type: 'warning' | 'success' | 'info' | 'error'
}

const defaultParams: Partial<IConfirmModal> = {
  color: 'info',
  onConfirm: () => {
    alert('onConfirm is not defined')
  },
  title: 'Confirme a ação',
  description: 'Essa ação é irreversível',
  cancel: 'Cancelar',
  confirm: 'Confirmar',
  type: 'info',
}

export const useConfirmModal = (params: Partial<IConfirmModal> = {}) => {
  const [open, setOpen] = React.useState(false)
  const classes = useStyles()
  const [
    {color, onConfirm, title, description, cancel, confirm, onClose, type},
    setState,
  ] = React.useState({
    ...defaultParams,
    ...params,
    onClose: (clicked?: boolean) => {
      if (!clicked) {
        onConfirmRef.current = () => {}
      }
      if (params.onClose && clicked) {
        params.onClose()
      }
      setOpen(false)
    },
  } as IConfirmModal)
  const onConfirmRef = React.useRef(onConfirm)
  const onCloseRef = React.useRef(onClose)

  const updateOnConfirm = (newOnConfirm: Function) => {
    onConfirmRef.current = newOnConfirm
  }

  const updateOnClose = (newOnClose: () => void) => {
    onCloseRef.current = (clicked?: boolean) => {
      if (!clicked) {
        onConfirmRef.current = () => {}
      }

      if (clicked) {
        newOnClose()
      }
      setOpen(false)
    }
  }

  const updateOnConfirmAndOpenModal = (newOnConfirm: Function) => {
    onConfirmRef.current = newOnConfirm
    setOpen(true)
  }

  const updateOnCloseAndOpenModal = (newOnClose: () => void) => {
    onCloseRef.current = newOnClose
    setOpen(true)
  }

  const updateModalStateAndOpenModal = ({
    onConfirm = () => {},
    onClose = (clicked?: boolean) => {
      if (clicked) {
        onConfirmRef.current = () => {}
      }
      if (params.onClose && clicked) {
        params.onClose()
      }
      setOpen(false)
    },
    ...newState
  }: Partial<IConfirmModal> & {onConfirm?: Function}) => {
    onConfirmRef.current = onConfirm
    onCloseRef.current = (clicked?: boolean) => {
      if (clicked) {
        onConfirmRef.current = () => {}
      }
      if (onClose && clicked) {
        onClose()
      }
      setOpen(false)
    }

    setState((oldState) => ({...oldState, ...newState}))

    setOpen(true)
  }

  const openConfirmModal = () => {
    setOpen(true)
  }

  const renderModal = () => {
    return (
      <Dialog
        open={open}
        onClose={() => onCloseRef.current()}
        style={{zIndex: 1400}}>
        <Box
          alignItems="space-between"
          display="grid"
          gridTemplateRows="min-content 1fr min-content"
          minWidth="400px"
          style={{
            borderTop: `3px solid ${modifiersType[type].primary}`,
          }}>
          <DialogTitle id="alert-dialog-title">
            <Box className={classes.header}>
              <div
                className={classes.headerIcon}
                style={{
                  background: modifiersType[type].primary,
                }}>
                {modifiersType[type].Icon}
              </div>
              <p className={classes.headerTitle}>{title}</p>
            </Box>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <p className={classes.description}>{description}</p>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                onCloseRef.current(true)
              }}
              variant="text"
              className={classes.buttonCancel}>
              {cancel}
            </Button>
            <Button
              onClick={() => {
                onConfirmRef.current()
                onCloseRef.current()
              }}
              variant="contained"
              style={{
                background: modifiersType[type].secondary,
                color: modifiersType[type].primary,
                boxShadow: 'none',
              }}
              className={classes.button}>
              {confirm}
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    )
  }

  return {
    renderModal,
    updateOnConfirm,
    openConfirmModal,
    updateModalStateAndOpenModal,
    updateOnConfirmAndOpenModal,
    updateOnClose,
    updateOnCloseAndOpenModal,
  }
}
