import { api } from 'api'
import {
  IChangePassword,
  IChangePasswordAPI,
  IChangePasswordCodeRequest,
  IConfirmationCodeApi,
  IUserComplete,
  IValidationEmailAPI,
} from 'domains/User'
import { endpointsAffiliation, endpointsAuth } from 'infra'
import qs from 'qs'
import { DefaultMessageApi } from 'types'

async function getValidationEmail(email: string): Promise<IValidationEmailAPI> {
  const response = await api.get(
    endpointsAuth.getEmailValidation.replace(':email', email),
  )
  return response.data
}

async function postSendCode(userId: string): Promise<DefaultMessageApi> {
  const response = await api.post(
    endpointsAuth.postSendCode.replace(':userId', userId),
  )
  return response.data
}

async function postConfirmationCode(
  userId: string,
  code: string,
): Promise<IConfirmationCodeApi> {
  const data = qs.stringify({
    value: code,
  })
  const response = await api.post(
    `${endpointsAuth.postConfirmationCode.replace(':userId', userId)}?${data}`,
  )
  return response.data
}

async function postChangePasswordCode(
  payload: IChangePasswordCodeRequest,
): Promise<IChangePasswordAPI> {
  const response = await api.post(endpointsAuth.postChangePassword, payload)
  return response.data
}

async function putChangePassword(
  userCredentialId: string,
  payload: IChangePassword,
): Promise<IChangePasswordAPI> {
  const response = await api.put(endpointsAuth.putChangePassword.replace(':userCredentialId', userCredentialId), payload)
  return response.data
}

async function postCredential(
  payload: IChangePassword,
): Promise<IChangePasswordAPI> {
  const response = await api.post(endpointsAuth.postCredential, payload)
  return response.data
}


async function postUserComplete(data: IUserComplete): Promise<any> {
  const response = await api.post(endpointsAuth.postUserComplete, data)
  return response.data
}

async function putUserComplete(userId: string, data: IUserComplete): Promise<any> {
  const response = await api.put(endpointsAuth.putUserComplete.replace(':userId', userId), data)
  return response.data
}

async function deleteUser(id: string): Promise<any> {
  const response = await api.delete(endpointsAffiliation.deleteAffiliation.replace(':id', id))
  return response.data
}

export const userApi = {
  getValidationEmail,
  postSendCode,
  postConfirmationCode,
  postChangePasswordCode,
  putChangePassword,
  postUserComplete,
  putUserComplete,
  postCredential,
  deleteUser
}
