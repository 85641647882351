import { useEffect, useMemo, useState } from 'react'

import { Button } from '@intraversa-lab/styleguide'
import { Add, EmailOutlined, ChevronRight } from '@mui/icons-material'
import { InputSearch, SearchBarMobile } from 'components'
import { useNavigate } from 'react-router-dom'

import { FilterListProfile } from '../Filter/FilterListProfile'
import * as S from '../styles/styles'
import { usersMock } from '../types/mock'
import { Tuser } from '../types/types'
import { useGetAffiliation } from 'domains/Affiliation/useCases'
import { useAuth } from 'services'
import { useDebounce } from 'infra/hooks/useDebounce'
import { Box, CircularProgress, Divider } from '@mui/material'
import moment from 'moment'
import { useGetRole } from 'domains/Role/useCase'

export const ListProfile = () => {
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search)
  const [users, setUser] = useState<Tuser[]>()
  const [showInputSearch, setShowInputSearch] = useState(false)
  const [orderBy, setOrderBy] = useState('user_id')

  const { user } = useAuth()

  const Navigate = useNavigate()

  const { isLoading, affiliation, isFetching, refetch } = useGetAffiliation({
    companyId: String(user?.companies[0].id)!,
    offset: 10,
    page: 0,
    name: debouncedSearch,
    sort: orderBy,
  })

  const { role } = useGetRole({
    page: 0,
    offset: 10,
  })

  function addNewProfile() {
    Navigate('create')
  }

  function showDetails(idUser: string) {
    Navigate(`edit/${idUser}`)
  }

  const lastUpdate = useMemo(() => {
    return new Date()
  }, [isFetching, isLoading])

  useEffect(() => {
    setUser(usersMock)
  }, [])

  useEffect(() => {
    refetch()
  }, [orderBy])

  return (
    <>
      <S.Container>
        <S.WrapperHeader>
          <h1 className="text-large font-semibold text-shade-10">Perfis</h1>
          <p style={{ marginTop: '16px' }}>
            Atualize e gerencie os perfis conectados a sua empresa.
          </p>
        </S.WrapperHeader>
      </S.Container>

      <S.Content>
        <S.WrapperSerach>
          <div className="filters">
            {showInputSearch ? (
              <div className="inputSearchMobile">
                <SearchBarMobile
                  placeholder={'Pesquisar'}
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  onClose={() => setShowInputSearch(!showInputSearch)}
                />
              </div>
            ) : (
              <>
                <InputSearch
                  placeholder="Pesquisar"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  onShowMobile={() => setShowInputSearch(!showInputSearch)}
                />
                <FilterListProfile orderBy={(v) => setOrderBy(v)} />
              </>
            )}
          </div>

          <Button
            startIcon={<Add />}
            onClick={() => addNewProfile()}
            className="newProfile"
            sx={{
              '&:hover': {
                backgroundColor: '#3C454D !important',
                color: '#F9F9F9',
              },
            }}
          >
            Adicionar Novo Perfil
          </Button>
        </S.WrapperSerach>

        <S.ContentListUser>
          {!isLoading &&
            affiliation &&
            affiliation.map((item) => (
              <div key={item.id}>
                <S.CardUser>
                  <S.Column>
                    <p className="first">{item.name}</p>
                    <span className="email">
                      <EmailOutlined
                        fontSize="small"
                        style={{ width: '12px' }}
                      />
                      {item.email}
                    </span>
                  </S.Column>

                  <S.Column>
                    <p>Cargo</p>
                    <span>{item.companyPosition}</span>
                  </S.Column>

                  <S.Column>
                    <p>Último Acesso</p>

                    <span>
                      {item.updatedAt
                        ? moment(item.updatedAt).format('DD/MM/YYYY HH:mm:ss')
                        : ''}
                    </span>
                  </S.Column>

                  <S.Column>
                    <p>Permissão</p>
                    <span>
                      {
                        role?.find((i) => i.id === item.roles[0]?.roleId)
                          ?.description
                      }
                    </span>
                  </S.Column>

                  <S.ContenetBtn>
                    <Button
                      variant="outlined"
                      endIcon={<ChevronRight />}
                      onClick={() => showDetails(item.id)}
                    >
                      Ver detalhes
                    </Button>
                  </S.ContenetBtn>
                </S.CardUser>
                <S.CardUserMobile key={user.id}>
                  <p className="name">{item.name}</p>
                  <span className="email">
                    <EmailOutlined
                      fontSize="small"
                      style={{ fontSize: '12px', marginTop: '5px' }}
                    />{' '}
                    {item.email}
                  </span>
                  <div className="contentBox">
                    <div className="item">
                      <p>Cargo</p>
                      <span>{item.companyPosition}</span>
                    </div>
                    <div className="item">
                      <p>Último Acesso</p>
                      <span>
                        {item.updatedAt
                          ? moment(item.updatedAt).format('DD/MM/YYYY HH:mm:ss')
                          : ''}
                      </span>
                    </div>
                  </div>
                  <div className="permission">
                    <p>Permissão</p>
                    <span>
                      {
                        role?.find((i) => i.id === item.roles[0]?.roleId)
                          ?.description
                      }
                    </span>
                  </div>

                  <div className="content-btn">
                    <Button
                      variant="outlined"
                      endIcon={<ChevronRight />}
                      style={{
                        width: 'max-content',
                      }}
                      onClick={() => showDetails(item.id)}
                    >
                      Ver detalhes
                    </Button>
                  </div>
                </S.CardUserMobile>
              </div>
            ))}

          {isLoading && (
            <Box
              display="flex"
              alignItems={'center'}
              justifyContent="center"
              mt="2"
            >
              <CircularProgress size={50} />
            </Box>
          )}

          {!isLoading && !affiliation && (
            <S.NotFound>
              <p>Algum erro inesperado, tente recarregar a página novamente!</p>
            </S.NotFound>
          )}
        </S.ContentListUser>
      </S.Content>
    </>
  )
}
