import { useMutation } from 'react-query'
import { MutationOptions } from 'infra'
import { DefaultMessageApi } from 'types'
import { userService } from '../userService'
import { toast } from '@intraversa-lab/styleguide'


export interface Variables {
  email: string
  name: string
  companyPosition: string
  companyId: string
  roles: string[]
  credential?: {
    password: string
    expirationDate: string
    changePasswordNextLogin: boolean
  }
}


export function usePostUserComplete(
  options?: MutationOptions<any>,
) {
  const mutation = useMutation<any, Error, Variables>({
    mutationFn: (variables: Variables) =>
      userService.postUserComplete(variables),
    retry: false,
    onError: (error) => {
      if (options?.onError) {
        options.onError(error.message)
      }
    },
    onSuccess: (data) => {
      toast({
        type: 'success',
        text: 'Perfil criado',
        description: 'Novo perfil criado com sucesso',
      })

      if (options?.onSuccess) {
        options.onSuccess(data)
      }
    },
  })

  return {
    isLoading: mutation.isLoading,
    postUserComplete: (variables: Variables) => mutation.mutate(variables),
    isSuccess: mutation.isSuccess,
    isError: mutation.isError,
  }
}