export enum QueryKeys {
  GetCompanyRPA = 'GetCompanyRPA',
  GetHistoryRPA = 'GetHistoryRPA',

  getAffiliation = 'GetAffiliation',
  getAffiliationById = 'GetAffiliationById',

  getRole = 'GetRole'
}

export enum LocalStorage {
  ACCESS_TOKEN = '@PORTICO:ACCESS_TOKEN',
  USER_LOGGED = '@PORTICO:USER_LOGGED',
  STATUS = '@PORTICO:STATUS',
  ENV = '@PORTICO:ENV',
  ROLES= "@PORTICO:ROLES",
}
