import {useMutation} from 'react-query'
import {useGetEnvs, useGetStatus} from 'domains/Common'
import {UserApi} from 'domains/User'
import {MutationOptions} from 'infra'
import {useAuth} from 'services'

import {authService} from '../authService'

interface Variables {
  email: string
  password: string
  isKeepLogin: boolean
}

export function useAuthSignIn(options?: MutationOptions<UserApi>) {
  const {saveUserLogged, saveEnvironments, saveStatus} = useAuth()
  const {getStatus} = useGetStatus({
    onSuccess: (data) => {
      saveStatus(data)
    },
  })
  const {getEnvs} = useGetEnvs({
    onSuccess: (data) => {
      saveEnvironments(data)
    },
  })
  const mutation = useMutation<UserApi, Error, Variables>({
    mutationFn: async ({email, password}) => {
      getStatus()
      getEnvs()
      return await authService.signIn({
        email,
        password,
      })
    },
    retry: false,
    onError: (error) => {
      if (options?.onError) {
        options.onError(error.message)
      }
    },
    onSuccess: (userLogged, {isKeepLogin}) => {
      if (options?.onSuccess) {
        options.onSuccess(userLogged)
      }
      if(!userLogged.credential.changePasswordNextLogin){
        saveUserLogged(userLogged, isKeepLogin)
      }
    },
  })

  return {
    isLoading: mutation.isLoading,
    signIn: (variables: Variables) => mutation.mutate(variables),
    isSuccess: mutation.isSuccess,
    isError: mutation.isError,
  }
}
