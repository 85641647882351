import {COLORS} from '@intraversa-lab/styleguide'
import {Box} from '@mui/material'
import {styled, css} from 'styled-components'

import {ChipStatusCardProps} from './CardIntegration'

export const Wrapper = styled(Box)`
  min-height: 208px;
  width: 100%;

  background: #fff !important;

  border: 1px solid ${COLORS.neutral[20]};
  border-radius: 8px;

  padding: 24px 24px 32px 24px;
`

export const Header = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const TitleCard = styled.p`
  margin-top: 16px;
  margin-bottom: 16px;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
`

const modifiersChip = {
  active: () => css`
    background: #d7ffea;
    color: #2dcf7a;
  `,
  inactive: () => css`
    background: #ffe7d2;
    color: #ff891c;
  `,
}

const modifiersChipPoint = {
  active: () => css`
    background: #2dcf7a;
  `,
  inactive: () => css`
    background: #ff891c;
  `,
}

export const WrapperChip = styled.div<ChipStatusCardProps>`
  ${({variant}) => css`
    max-width: max-content;
    height: 32px;
    border-radius: 4px;
    padding: 4px 12px;

    display: flex;
    align-items: center;

    ${modifiersChip[variant]()}
  `}
`

export const Element = styled(Box)`
  .description {
    display: flex;
    gap: 8px;
    align-items: center;
    margin-bottom: 8px;

    p {
      color: ${COLORS.neutral[80]};
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      margin: 0;
      line-height: 24px;
    }

    @media (max-width: 650px) {
      flex-direction: column;
      align-items: baseline;
    }
  }

  p {
    margin: 0;
    color: #161f28;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }

  
`
