import { affiliationApi } from "./affiliationApi"
import { IAffiliation } from "./affiliationTypes"
import { toast } from '@intraversa-lab/styleguide'


async function getAffiliation({
  companyId,
  page = 0,
  offset = 10,
  name,
  sort
}: {
  companyId: string
  page: number
  offset: number
  name: string
  active?: boolean,
  sort: string
}): Promise<IAffiliation[]> {
  try {
    const respose = await affiliationApi.getAFfiliation({
      companyId,
      page,
      offset,
      name,
      sort
    })

    return respose
  } catch (error) {
    toast({
      type: 'error',
      text: 'Erro ao buscar Perfil.',
      description: 'Tente novamente em instantes.',
    })
    throw new Error('Tente novamente em instantes.')
  }
}

async function getAffiliationByCompanyByUserId({ companyId, userId }: {
  companyId: string
  userId: string,
}): Promise<IAffiliation> {
  try {
    const respose = await affiliationApi.getAffiliationByCompanyByUserId({ companyId, userId })
    return respose
  } catch (error) {
    toast({
      type: 'error',
      text: 'Erro ao buscar Affiliation.',
      description: 'Tente novamente em instantes.',
    })
    throw new Error('Tente novamente em instantes.')
  }
}

export const AffilitationService = {
  getAffiliation,
  getAffiliationByCompanyByUserId
}
