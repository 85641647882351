import { useCallback, useState } from 'react';

interface IUseLocalStorageProps<T> {
  storageKey: string;
  initialValue?: T;
}

/**
 * Hook for managing state in localStorage.
 * @param storageKey - Key for the localStorage item.
 * @param initialValue - Optional initial value for the state.
 * @returns A tuple containing the state and a function to update it.
 */
export function useLocalStorage<T>({
  storageKey,
  initialValue,
}: IUseLocalStorageProps<T>): readonly [T | undefined, (value: T) => void] {
  const [state, setState] = useState<T | undefined>(() => {
    try {
      const storageValue = localStorage.getItem(storageKey);
      return storageValue ? JSON.parse(storageValue) : initialValue;
    } catch {
      return initialValue;
    }
  });

  const setValue = useCallback(
    (value: T) => {
      try {
        setState(value);
        localStorage.setItem(storageKey, JSON.stringify(value));
      } catch (error) {
        console.error(error);
      }
    },
    [storageKey]
  );

  return [state, setValue] as const;
}