import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Stack, Typography } from "@mui/material";
import { ButtonPortico, TextFieldRHF } from "components";
import { useAuthSignIn } from "domains";
import { useForm } from "react-hook-form";

import { NewPasswordSchema, newPasswordSchema } from "./newPasswordSchema";
import {
  IChangePasswordStepsProps,
  REDEFINE_PASSWORD,
} from "../../RedefinePassword";
import { Button } from "@intraversa-lab/styleguide";
import { LocalStorage } from "infra";
import * as S from "../../styles";

export const NewPassword = ({
  handleNext,
}: IChangePasswordStepsProps) => {

  const userLogger =  JSON.parse(window.localStorage.getItem(LocalStorage.USER_LOGGED))
  const { control, handleSubmit, getValues, setError, formState: { errors, isValid, isSubmitted } } = useForm<NewPasswordSchema>({
    resolver: zodResolver(newPasswordSchema),
    mode: "onChange",
  });
  const { signIn } = useAuthSignIn({
    onSuccess: () => {
      handleNext(REDEFINE_PASSWORD.CONFIRM_PASSWORD);
    },
    onError: () => {
      setError("password", { type: 'custom', message: 'Senha inválida' })
    }
  })

  const onSubmit = () => {
    signIn({
      email: userLogger.email,
      password: getValues().password,
      isKeepLogin: true,
    })
  }

  return (
    <>
    
    <S.ResetModal>

        <Stack spacing={3}>
          <Typography
            variant="h1"
            fontFamily={"Merriweather"}
            color={"#161F28"}
            fontSize={"36px"}
          >
            Redefinir senha
          </Typography>

          <Typography
            variant="h6"
            fontFamily={"Roboto"}
            color={"#75808A"}
            style={{ marginTop: "24px" }}
          >
            Para redefinir a senha primeiro confirme sua identidade.
          </Typography>

          <TextFieldRHF
            required
            control={control}
            type="password"
            name="password"
            placeholder="******"
            error={!isValid && isSubmitted}
            helperText={""}
            label={"Senha Atual"}
            rules={{ required: "" }}
            style={{ marginBottom: "24px", marginTop: "10px !important" }}
          />
          {!isValid}

        {!isValid && isSubmitted && (
            <p
              style={{
                color: "#FF4B38",
                fontSize: "12px",
                marginTop: "10px",
                padding: "0 16px"
              }}
            >
              {errors.password
                ? errors.password.message
                : "Senha inválida."}
            </p>
          )}
    
          
          <S.footerModal>
            <ButtonPortico onClick={handleSubmit(onSubmit)}>
              Confirmar
            </ButtonPortico>

            <Box display={"flex"} justifyContent={"center"} padding={"36px 0 0"}>
              <S.ButtonForgotPassword onClick={() => handleNext(REDEFINE_PASSWORD.VALIDATE_EMAIL)}>
                Esqueceu a senha?
              </S.ButtonForgotPassword>
            </Box>
          </S.footerModal>
        </Stack>

        
      </S.ResetModal>
    </>
  );
};
