import { useQuery } from "react-query"
import { roleService } from "../roleService"
import { LocalStorage, QueryKeys } from "infra"

export function useGetRole({
  page = 0,
  offset = 10,
  sort
}: {
  page: number
  offset: number
  sort?: string
}) {
  const { data, isLoading, isError, refetch, isFetching } = useQuery({
    queryKey: [QueryKeys.getRole, { page, offset }],
    queryFn: async () => {
      const roles = await roleService.getRole({
        page,
        offset,
        sort
      })
      localStorage.setItem(LocalStorage.ROLES, JSON.stringify(roles))
      return roles
    },
  })

  return {
    role: data,
    isLoading,
    isFetching,
    isError,
    refetch,
  }
}