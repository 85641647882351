import React, { Ref } from 'react'

import Select, {
  ControlProps,
  GroupBase,
  Props,
  SelectInstance,
  components,
} from 'react-select'

import * as S from './styles'

export const IconArrowDown = () => {
  return (
    <svg width="8" height="4" viewBox="0 0 8 4" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.9974 4.00026L0.664062 0.683594H7.33073L3.9974 4.00026Z" fill="#161F28" />
      <path d="M3.9974 4.00026L0.664062 0.683594H7.33073L3.9974 4.00026Z" fill="black" fill-opacity="0.2" />
      <path d="M3.9974 4.00026L0.664062 0.683594H7.33073L3.9974 4.00026Z" fill="black" fill-opacity="0.2" />
      <path d="M3.9974 4.00026L0.664062 0.683594H7.33073L3.9974 4.00026Z" fill="black" fill-opacity="0.2" />
      <path d="M3.9974 4.00026L0.664062 0.683594H7.33073L3.9974 4.00026Z" fill="black" fill-opacity="0.2" />
    </svg>

  )
}

interface ISelectProps extends Props {
  isError?: boolean
}

export const InputSelect = React.forwardRef<SelectInstance, ISelectProps>(
  function ReactSelect<
    Option,
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>,
  >(
    { isError, ...props }: ISelectProps,
    ref: Ref<SelectInstance<Option, IsMulti, Group>>,
  ) {

    function checkError(isOpen: boolean, isError: boolean){
      if(isOpen && !isError) {
        return '#161F28'
      }else if (!isOpen && !isError ) {
        return 'transparent'
      } else if(isOpen && isError) {
        return '#FF4B38'
      } else if(!isOpen && isError) {
        return '#FF4B38'
      }
    }

    function checkBottomColor(isOpen: boolean, isError: boolean){
      if(isOpen) {
        return 'transparent'
      }else if(!isOpen && isError) {
        return '#FF4B38'
      }else if(!isOpen && !isError){
        return 'transparent'
      }
    }

    function checkBottomColorDrop(isOpen: boolean, isError?: boolean){
      if(isOpen && !isError ) {
        return 'transparent'
      }else if(!isOpen && isError){
        return '#FF4B38'
      }else if(isOpen && isError ){
        return 'transparent'
      }
    }


    const customStyles = {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any, prettier/prettier
      control: (base: any, props: ControlProps<Option, IsMulti, Group>) => ({
        ...base,
        background: props.selectProps.menuIsOpen ? '#F9F9F9' : '#EFF2F4',
        // match with the menu
        borderRadius: props.selectProps.menuIsOpen
          ? '16px 16px 0 0'
          : '16px 16px 16px 16px',
        height: '48px',
        // letterSpacing: 10,
        padding: '12px 24px',
        fontFamily: 'Roboto',
        // Overwrittes the different states of border
        // borderColor: props.selectProps.menuIsOpen && !isError ? '#161F28' : 'transparent',
        borderColor: checkError(props.selectProps.menuIsOpen, isError),
        borderBottomColor: checkBottomColor(props.selectProps.menuIsOpen, isError),

        fontWeight: 400,
        // Removes weird border around container
        boxShadow: props.isFocused ? null : null,
        '&:hover': {
          // Overwrittes the different states of border
          backgroundColor: props.selectProps.menuIsOpen ? '#F9F9F9' : '#F9F9F9',
          borderColor: isError ? '#FF4B38': '#161F28',
          borderBottomColor:  checkBottomColorDrop(props.selectProps.menuIsOpen, isError)
        },
      }),

      menu: (base: any, state: any) => ({
        ...base,
        marginTop: 0,
        width: '100%',
        border: isError ? '1px solid #FF4B38':  '1px solid #161F28',
        backgroundColor: '#F9F9F9',
        borderTopColor: 'transparent',
        boxShadow: 'none',
        borderRadius: '0px 0px 16px 16px',
        zIndex: 2,
      }),

      menuList: (base: any, state: any) => ({
        ...base,
        width: '100%',
        borderRadius: 4,
        padding: '8px 8px 16px 8px',
      }),

      option: (styles: any, { isFocused, isSelected }: any) => ({
        color: '#59636B',
        fontWeight: 500,
        paddingLeft: '24px',
        zindex: 2,
        height: '58px',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        fontFamily: 'Roboto',

        '&:hover': {
          backgroundColor: '#EFF2F4',
        },

        '&:active': {
          backgroundColor: '#DFE4E9',
        },

        // backgroundColor: isSelected ? 'red' : 'transparent',
      }),

      // dropdownIndicator: (base, state) => {
      //   const changes = {
      //     padding: 0,
      //   }
      //   return Object.assign(base, changes)
      // },
    }

    const DropdownIndicator = (props) => {
      return (
        components.DropdownIndicator && (
          <components.DropdownIndicator {...props}>
            <IconArrowDown />
          </components.DropdownIndicator>
        )
      )
    }

    return (
      <S.Container>
        <Select
          {...props}
          name={props.name}
          ref={ref}
          components={{ DropdownIndicator }}
          styles={customStyles}

        />
      </S.Container>
    )
  },
)
