import axios from 'axios'
import {AuthCredentialsService} from 'services'
import {authStorage} from 'services/auth/authStorage'
import {config} from 'utils/config'

import {authService} from '../domains'

export const api = axios.create({
  baseURL: `${config.baseUrl}${config.basUrlVersion}`,
  headers: {
    Authorization: authStorage.getAccessToken(),
  },
})

type InterceptorProps = Pick<
  AuthCredentialsService,
  'removeAccessToken' | 'saveAccessToken' | 'access_token'
>

export function registerInterceptor({
  removeAccessToken,
  saveAccessToken,
  access_token,
}: InterceptorProps) {
  const interceptor = api.interceptors.response.use(
    (response) => response,
    async (responseError) => {
      const failedRequest = responseError.config

      if (responseError.response.status === 401) {
        if (failedRequest.sent) {
          removeAccessToken()
          return Promise.reject(responseError)
        }

        failedRequest.sent = true

        const newAuthCredentials = await authService.authToken()
        saveAccessToken(newAuthCredentials.access_token)

        failedRequest.headers.Authorization = `Bearer ${newAuthCredentials.access_token}`
        api.defaults.headers.Authorization = `Bearer ${newAuthCredentials.access_token}`

        return api(failedRequest)
      }

      return Promise.reject(responseError)
    },
  )

  return () => api.interceptors.response.eject(interceptor)
}
