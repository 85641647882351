import { passwordRegex } from 'utils/regex'
import { z } from 'zod'

export const newPasswordSchema = z
  .object({
    password: z.string({
      required_error: '',
    })
      .min(6, 'Senha inválida')
      .regex(/[a-z]/, 'Senha inválida')
      .regex(/[A-Z]/, 'Senha inválida')
      .regex(/\d/, 'Senha inválida')
      .regex(
        /[@$!%*?&]/,
        'Senha inválida',
      )
      .regex(
        passwordRegex,
        'Senha inválida',
      ),
    confirmationPassword: z.string()
      .min(1, 'Campos obrigatórios')
      .min(6, 'Senha inválida'),
    changePasswordNextLogin: z.boolean()
  })
  .refine((data) => data.password === data.confirmationPassword, {
    message: 'As senhas precisam ser iguais.',
    path: ['confirmationPassword'],
  })

export type NewPasswordSchema = z.infer<typeof newPasswordSchema>
