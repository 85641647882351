import {styled} from 'styled-components'

export const Content = styled.div`
  position: relative;
  flex: 1;

  @media (max-width: 750px) {
    flex: 0;
  }
`

type isFocused = {
  isFocused: boolean
}

export const WrapperInput = styled.div<isFocused>`
  display: flex;
  align-items: center;
  flex: 1;
  gap: 10px;
  background-color: ${(props) => (props.isFocused ? 'transparent' : '#eff2f4')};
  border-radius: 40px 40px;
  padding-left: 12px;
  padding-right: 24px;
  height: 44px;
  border: 1px solid transparent;
  border-color: ${(props) => (props.isFocused ? '#BEC9D2' : 'transparent')};

  &:hover {
    border: 1px solid var(--Neutral-40, #bec9d2);
    background-color: transparent;
  }

  input {
    background-color: transparent;
    border: none;
    outline: none;
    width: 100%;
    height: 100%;
    padding: 12px 0;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    color: #161f28;

    &::placeholder {
      font-weight: 500;
      color: #929ea8;
    }
  }

  @media (max-width: 650px) {
    display: none;
  }
`

export const ButonSearch = styled.div`
  height: 44px;
  width: 44px;
  border: 1px solid #bec9d2;
  border-right: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
  @media (max-width: 650px) {
    display: flex;
  }
`
