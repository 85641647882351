import {passwordRegex} from 'utils/regex'
import {z} from 'zod'

export const loginSchema = z.object({
  email: z.string({
    required_error: 'sdasdasjdk'
  }),
  password: z.string({
    required_error: 'asdasdadakdjas'
  }),
  keepLogin: z.boolean(),
})

export type LoginSchema = {
  keepLogin: boolean
} & z.infer<typeof loginSchema>
