import { useQuery } from "react-query";
import { QueryKeys } from "infra";

import { rpaService } from "../rpaService";

export function useCompanyRPA({
  companyId,
  page = 0,
  offset = 10,
  name,
  active,
  sort
}: {
  companyId: string;
  page: number;
  offset: number;
  name: string;
  active?: boolean;
  sort: string;
}) {
  const { data, isLoading, isError, refetch, isFetching } = useQuery({
    queryKey: [QueryKeys.GetCompanyRPA, { companyId, name }],
    queryFn: () => {
      return rpaService.getCompanyRPA({
        companyId,
        page,
        offset,
        name,
        active,
        sort
      });
    },
    staleTime: 1000 * 30 // 10 seconds
  });

  return {
    rpas: data,
    isLoading,
    isFetching,
    isError,
    refetch
  };
}
