


import { useMutation } from 'react-query'
import { MutationOptions } from 'infra'
import { DefaultMessageApi } from 'types'

import { userService } from '../userService'
import { IChangePassword, IChangePasswordAPI } from '../userTypes'
import { toast } from '@intraversa-lab/styleguide'

type Variables = IChangePassword

export function usePostCredential(
  options?: MutationOptions<IChangePasswordAPI>,
) {
  const mutation = useMutation<IChangePasswordAPI, Error, Variables>({
    mutationFn: (variables: Variables) =>
      userService.postCredential(variables),
    retry: false,
    onError: (error) => {
      if (options?.onError) {
        options.onError(error.message)
      }
    },
    onSuccess: (data) => {
      toast({
        type: 'success',
        text: 'Senha Criada',
        description: 'A senha foi criada com sucesso',
      })
      if (options?.onSuccess) {
        options.onSuccess(data)
      }
    },
  })

  return {
    isLoading: mutation.isLoading,
    postCredential: (variables: Variables) => mutation.mutate(variables),
    isSuccess: mutation.isSuccess,
    isError: mutation.isError,
  }
}