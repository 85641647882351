import { useEffect, useState } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import {
  ButtonPortico,
  InputFieldRHF,
  InputPortico,
  Modal,
  TemplateHome,
} from "components";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as S from "./styles";
import { UserProfileSchema, userProfileSchema } from "./userProfileSchema";

import { ChangePassword } from "./RedefinePassword";
import { LocalStorage } from "infra";
import { useConfirmModal } from "@intraversa-lab/styleguide";
import { UserApi, usePutUserComplete } from "domains";
import { useLocalStorage } from "hooks";
import { useAuth } from "services";
import { Divider } from "@mui/material";

export const UserProfile = () => {

  const [userLogger, setUserLogger] = useLocalStorage<UserApi>({ storageKey: LocalStorage.USER_LOGGED, })

  const { user } = useAuth()
  const navigate = useNavigate();

  const [resetKey, setResetKey] = useState(false);
  const { renderModal, updateModalStateAndOpenModal } = useConfirmModal();
  const { putUserComplete } = usePutUserComplete(user.id, {
    onSuccess: (data) => {
      const payload = {
        ...user,
        ...data
      }

      setUserLogger(payload)
    },
  });


  const methods = useForm<UserProfileSchema>({
    resolver: zodResolver(userProfileSchema),
    defaultValues: {
      nomeCompleto: "",
      email: "",
      cargo: "",
    },
    mode: "onTouched",
    reValidateMode: "onSubmit",
  });

  const {
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors, isDirty },
    control,
  } = methods;

  function CallModalCreateProfile(data) {
    updateModalStateAndOpenModal({
      type: "warning",
      title: "Atenção",
      description:
        "Ao salvar as alterações você irá alterar dados do seu perfil. Deseja continuar?",
      maxWidth: 449,
      onConfirm: () => editProfile(data),
    });
  }

  function CallModalCancel() {
    updateModalStateAndOpenModal({
      type: 'warning',
      title: 'Atenção',
      description: 'Você irá descartar as alterações realizadas. Deseja continuar?',
      maxWidth: 449,
      onConfirm: () => navigate('/')
    })
  }

  function editProfile(data: UserProfileSchema) {
    const payload = {
      email: data.email,
      name: data.nomeCompleto,
      companyPosition: data.cargo,
      companyId: user.companies[0].id,
      roles: user.roles.map((e) => e.roleId),
    };
    putUserComplete(payload);
  }

  useEffect(() => {
    if (userLogger && !getValues().cargo && !getValues().nomeCompleto && !getValues().email) {
      setValue("nomeCompleto", userLogger.name);
      setValue("email", userLogger.email);
      setValue("cargo", userLogger.companyPosition);
    }
  }, [user]);


  function onSubmit(data: UserProfileSchema) {
    console.log(data);
  }

  function resetModal() {
    setResetKey(false);
  }

  return (
    <>
      <TemplateHome>
        <S.Container>
        <S.WrapperHeader>
          <h1 className="text-large font-semibold text-shade-10">Perfil do usuário</h1>
          <p>Atualize e gerencie suas informações de cadastro</p>
        </S.WrapperHeader>
      </S.Container>


        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(CallModalCreateProfile)}>
            <S.ContentDescription>
              <div className="wrapper">
                <div className="info">
                  <span>Nome Completo</span>
                  <span>Alterar nome e sobrenome</span>
                </div>

                <div style={{ width: "100%" }}>
                  <InputFieldRHF
                    rules={{ required: "Obrigatório" }}
                    placeholder="Nome Completo"
                    control={control}
                    name="nomeCompleto"
                  />
                </div>
              </div>
            </S.ContentDescription>

            <S.ContentDescription>
              <div className="wrapper">
                <div className="info">
                  <span>Email</span>
                  <span>Alterar email da conta</span>
                </div>

                <div style={{ width: "100%" }}>
                  <InputFieldRHF
                    rules={{ required: "Obrigatório" }}
                    placeholder="Email"
                    control={control}
                    name="email"
                  />
                </div>
              </div>
            </S.ContentDescription>

            <S.ContentDescription>
              <div className="wrapper">
                <div className="info">
                  <span>Cargo</span>
                  <span>Alterar o cargo na empresa</span>
                </div>

                <div style={{ width: "100%" }}>
                  <InputFieldRHF
                    rules={{ required: "Obrigatório" }}
                    placeholder="Cargo"
                    control={control}
                    name="cargo"
                  />
                </div>
              </div>
            </S.ContentDescription>

            <S.ContentDescription>
              <div className="wrapper">
                <div className="info">
                  <span>Configurações da conta</span>
                  <span>Alterar senha</span>
                </div>

                <div className="content-button">
                  <ButtonPortico
                    variant="outlined"
                    type="button"
                    onClick={() => setResetKey(true)}
                  >
                    Redefinir Senha
                  </ButtonPortico>
                </div>
              </div>
            </S.ContentDescription>
            <S.ContenetBtnGroup>
              <ButtonPortico
                type="button"
                variant="outlined"
                onClick={isDirty ? CallModalCancel : () => navigate("/")}>
                Cancelar
              </ButtonPortico>
              <ButtonPortico
                type="submit"
              >
                Salvar Alterações
              </ButtonPortico>
            </S.ContenetBtnGroup>
          </form>
        </FormProvider>
        {renderModal()}

      </TemplateHome>
      <Modal
        isOpen={resetKey}
        onClose={() => {
          setResetKey(false);
        }}
      >
        <S.ResetModal>
          <ChangePassword resetModal={resetModal}/>
        </S.ResetModal>
      </Modal>


    </>
  );
};
