import { useMutation } from 'react-query'
import { MutationOptions } from 'infra'
import { DefaultMessageApi } from 'types'
import { userService } from '../userService'
import { toast } from '@intraversa-lab/styleguide'


export interface PutUserCompleteVariables {
  email: string
  name: string
  companyPosition: string
  companyId: string
  roles: string[]
  credential?: {
    password: string
    expirationDate: string
    changePasswordNextLogin: boolean
  }
}


export function usePutUserComplete(
  userId: string,
  options?: MutationOptions<any>,
) {
  const mutation = useMutation<any, Error, PutUserCompleteVariables>({
    mutationFn: (payload: PutUserCompleteVariables) =>
      userService.putUserComplete(userId, payload),
    retry: false,
    onError: (error) => {
      if (options?.onError) {
        options.onError(error.message)
      }
    },
    onSuccess: (data) => {
      toast({
        type: 'success',
        text: 'Alterações salvas',
        description: 'Suas alterações foram salvas com sucesso',
      })

      if (options?.onSuccess) {
        options.onSuccess(data)
      }
    },
  })

  return {
    isLoading: mutation.isLoading,
    putUserComplete: (payload: PutUserCompleteVariables) => mutation.mutate(payload),
    isSuccess: mutation.isSuccess,
    isError: mutation.isError,
  }
}