import { Routes as Router, Route, Navigate } from 'react-router-dom'
import { useAuth } from 'services'

import { authRoutes } from './authRoutes'
import { privateRoutes } from './privateRoutes'

export const Routes = () => {
  const { isAuthenticated } = useAuth()

  return (
    <Router>
      {authRoutes.map((route, index) => {
        if (isAuthenticated) {
          return (
            <Route
              key={index}
              path={route.path}
              element={<Navigate to="/home" />}
            />
          )
        }
        return (
          <Route key={index} path={route.path} element={<route.template />}>
            {route.subRoutes.map((subRoute, indexSubroute) => (
              <Route
                key={indexSubroute}
                path={subRoute.path}
                element={<subRoute.component />}
              />
            ))}
          </Route>
        )
      })}

      {privateRoutes.map((route, index) => {
        const hasSubRoute = !!route?.subRoutes?.length
        if (route.isPrivate && !isAuthenticated) {
          return (
            <Route
              key={index}
              path={route.path}
              element={<Navigate to="/" />}
            />
          )
        }
        if (hasSubRoute && route.template) {
          return (
            <Route key={index} path={route.path} element={<route.template />}>
              {route.subRoutes.map((subRoute, indexSubroute) => (
                <Route
                  key={indexSubroute}
                  path={subRoute.path}
                  element={<subRoute.component />}
                />
              ))}
            </Route>
          )
        }
        if (route.component) {
          return (
            <Route
              key={index}
              path={route.path}
              element={<route.component />}
            />
          )
        }
        return <Route key={index} path="*" element={<div>nao existe</div>} />
      })}
      <Route path="*" element={<div>nao existe</div>} />
    </Router>
  )
}
