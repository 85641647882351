import { ICommonEnvironment, ICommonStatus, UserApi } from 'domains'
import { LocalStorage } from 'infra'

function getAccessToken(): string | null {
  const accessTokenLocalStorage = window.localStorage.getItem(
    LocalStorage.ACCESS_TOKEN,
  )
  if (accessTokenLocalStorage) {
    const parseAccessToken = JSON.parse(accessTokenLocalStorage)
    return parseAccessToken
  }
  return null
}

async function setAccessToken(value: string) {
  window.localStorage.setItem(LocalStorage.ACCESS_TOKEN, JSON.stringify(value))
}

async function removeAccessToken() {
  window.localStorage.removeItem(LocalStorage.ACCESS_TOKEN)
}

function getUserLogged(): UserApi | null {
  const userLoggedLocalStorage = window.localStorage.getItem(
    LocalStorage.USER_LOGGED,
  )
  if (userLoggedLocalStorage) {
    const parseUser = JSON.parse(userLoggedLocalStorage)
    return parseUser
  }
  return null
}

async function setUser(value: UserApi) {
  window.localStorage.setItem(LocalStorage.USER_LOGGED, JSON.stringify(value))
}

async function removeUser() {
  window.localStorage.removeItem(LocalStorage.USER_LOGGED)
}

function getStatus(): ICommonStatus[] | null {
  const dataStorage = window.localStorage.getItem(LocalStorage.STATUS)
  if (dataStorage) {
    const parseDataStorage = JSON.parse(dataStorage)
    return parseDataStorage
  }
  return null
}

async function setStatus(value: ICommonStatus[]) {
  window.localStorage.setItem(LocalStorage.STATUS, JSON.stringify(value))
}

async function removeStatus() {
  window.localStorage.removeItem(LocalStorage.STATUS)
}

function getEnvironment(): ICommonEnvironment[] | null {
  const dataStorage = window.localStorage.getItem(LocalStorage.ENV)
  if (dataStorage) {
    const parseDataStorage = JSON.parse(dataStorage)
    return parseDataStorage
  }
  return null
}

async function setEnvironment(value: ICommonEnvironment[]) {
  window.localStorage.setItem(LocalStorage.ENV, JSON.stringify(value))
}

async function removeEnvironment() {
  window.localStorage.removeItem(LocalStorage.ENV)
}

export const authStorage = {
  getAccessToken,
  setAccessToken,
  removeAccessToken,
  getUserLogged,
  setUser,
  removeUser,
  getStatus,
  setStatus,
  getEnvironment,
  removeStatus,
  setEnvironment,
  removeEnvironment,
}
