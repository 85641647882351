import { useEffect, useMemo, useState } from 'react'

import { Text, Button } from '@intraversa-lab/styleguide'
import FilterListIcon from '@mui/icons-material/FilterList'
import { Box, CircularProgress, Divider, Grid } from '@mui/material'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { InputSearch, SearchBarMobile, Modal } from 'components'
import { TemplateHome } from 'components/template/TemplateHome/TemplateHome'
import {
  useCompanyRPA,
  useRestartRPA,
  useStartRPA,
  useStopRPA,
} from 'domains/Rpa'
import { useCommon, useConfirmModal } from 'hooks'
import { useDebounce } from 'infra'
import moment from 'moment'
import { useAuth } from 'services'

import {
  CardIntegration,
  CardIntegrationType,
  ENUM_OPTIONS_CARD_INTEGRATION,
} from './components/CardIntegration/CardIntegration'
import { FilterHome } from './components/Filter/FilterHome'
import { TabButton } from './components/TabButton/TabButton'
import * as S from './styles'

export const Home = () => {
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search)
  const [_, setTabSelected] = useState(0)

  const { renderModal, updateModalStateAndOpenModal } = useConfirmModal()
  const { user } = useAuth()
  const { checkStatusActive } = useCommon()

  const [showInputSearch, setShowInputSearch] = useState(false)
  const [findSearch, setFindSearch] = useState('')
  const [showModalHistorico, setShowModalHistorico] = useState(false)

  const [filterBy, setFilterBy] = useState<string | null>(null)
  const [orderBy, setOrderBy] = useState('updated_at')

  const isActive = filterBy === 'ativo' ? true : false

  const { isLoading, rpas, isFetching, refetch } = useCompanyRPA({
    companyId: String(user?.companies[0].id)!,
    offset: 20,
    page: 0,
    name: debouncedSearch,
    active: filterBy !== null ? isActive : undefined,
    sort: orderBy,
  })

  const { stopRPA, isLoading: isLoadingStop } = useStopRPA({
    onSuccess: () => refetch(),
  })

  const { restartRPA, isLoading: isLoadingRestart } = useRestartRPA({
    onSuccess: () => refetch(),
  })

  useEffect(() => {
    refetch()
  }, [orderBy, filterBy])

  const isLoadingDefault = useMemo(() => {
    return isLoadingRestart || isLoading || isLoadingStop
  }, [isLoading, isLoadingStop, isLoadingRestart])

  const lastUpdate = useMemo(() => {
    return new Date()
  }, [isFetching, isLoading])

  const handleSelectOption = (
    value: string,
    currentStatus: CardIntegrationType,
    rpaId: string,
  ) => {
    switch (value) {
      case ENUM_OPTIONS_CARD_INTEGRATION.PAUSAR:
        if (currentStatus === 'active') {
          return updateModalStateAndOpenModal({
            title: 'Pausar Integração',
            description:
              'O processo será temporariamente suspenso até ser reiniciado. Deseja prosseguir?',
            type: 'error',
            onConfirm: () =>
              stopRPA({
                companyId: String(rpaId)!,
                userId: String(user?.id)!,
              }),
          })
        }
        return updateModalStateAndOpenModal({
          title: 'Restart Integração',
          description: 'O processo será  reiniciado. Deseja prosseguir?',
          type: 'error',
          onConfirm: () =>
            restartRPA({
              companyId: String(rpaId)!,
              userId: String(user?.id)!,
            }),
        })
      case ENUM_OPTIONS_CARD_INTEGRATION.HISTORICO:
        return setShowModalHistorico(true)

      case ENUM_OPTIONS_CARD_INTEGRATION.LOG:
        break

      case ENUM_OPTIONS_CARD_INTEGRATION.CONFIGURACOES:
    }
  }

  function createData(acao: string, horario: string, usuario: string) {
    return { acao, horario, usuario }
  }

  const rows = [
    createData('Pausada', '08/12/2023', 'Mariana Camargo'),
    createData('Inativa', '08/12/2023', 'Jane Doe'),
    createData('Reativada', '08/12/2023', 'Mariana Camargo'),
  ]

  return (
    <TemplateHome>
      <S.WrapperContent>
        <div className="content_header">
          <h1 className="text-large font-semibold text-shade-10">
            Integrações
          </h1>

          <Box
            display="flex"
            alignItems="center"
            mt={2}
            style={{ height: '20px' }}
          >
            <Text type="labelLg" fontWeight={500}>
              Última atualização{' '}
              <span>{moment(lastUpdate).format('DD/MM/YYYY HH:mm:ss')}</span>
            </Text>
            {isFetching && (
              <Box ml={2}>
                <CircularProgress size={20} />
              </Box>
            )}
          </Box>
        </div>

        <div className="content_body">
          <S.HeaderFilter>
            <TabButton
              onChange={(v) => {
                setTabSelected(v)
              }}
            />

            <div className="filters">
              {showInputSearch ? (
                <div className="inputSearchMobile">
                  <SearchBarMobile
                    placeholder={'Pesquisar'}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    onClose={() => setShowInputSearch(!showInputSearch)}
                  />
                </div>
              ) : (
                <>
                  <InputSearch
                    placeholder="Pesquisar"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    onShowMobile={() => setShowInputSearch(!showInputSearch)}
                  />
                  <FilterHome
                    filterBy={(v) => setFilterBy(v)}
                    orderBy={(v) => setOrderBy(v)}
                  />
                </>
              )}
            </div>
          </S.HeaderFilter>

          {isLoadingDefault && (
            <Box
              display="flex"
              alignItems={'center'}
              justifyContent="center"
              mt="2"
            >
              <CircularProgress size={50} />
            </Box>
          )}

          {!isLoadingDefault && (
            <Grid container spacing={2}>
              {rpas.length > 0 ? (
                rpas.map((item) => (
                  <Grid key={item.id} item xs={12} sm={12} md={4} lg={4}>
                    <CardIntegration
                      variant={
                        checkStatusActive(item.status) ? 'active' : 'inactive'
                      }
                      name={item.name}
                      rpaId={item.id}
                      baseUrlRpa={item.baseUrl}
                      route={item.route}
                      handleSelectOption={handleSelectOption}
                      createdAt={new Date(item.createdAt)}
                      lastChangeAt={new Date(item.updatedAt)}
                    />
                  </Grid>
                ))
              ) : (
                <S.NotFound>
                  <p>Nenhum registro encontrado!</p>
                </S.NotFound>
              )}
            </Grid>
          )}
        </div>
      </S.WrapperContent>

      {renderModal()}

      <Modal
        maxWidth={1120}
        isOpen={showModalHistorico}
        onClose={() => setShowModalHistorico(!showModalHistorico)}
      >
        <Box gap={3} sx={{ display: 'flex' }} flexDirection={'column'}>
          <Text
            type="labelLg"
            fontSize={36}
            fontFamily={'Merriweather'}
            color={'#161F28'}
            fontWeight={400}
          >
            Histórico
          </Text>

          <Text type="labelLg" fontWeight={400}>
            Confira abaixo o histórico de ações da Integração 01
          </Text>

          <Box sx={{ display: 'flex' }} gap={1}>
            <InputSearch
              placeholder="Pesquisa"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <Button startIcon={<FilterListIcon />} variant="outlined">
              Filtrar por
            </Button>
          </Box>

          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Dessert (100g serving)</TableCell>
                  <TableCell align="right">Ação</TableCell>
                  <TableCell align="right">Horário</TableCell>
                  <TableCell align="right">Usuário</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow
                    key={row.acao}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.acao}
                    </TableCell>
                    <TableCell align="right">{row.acao}</TableCell>
                    <TableCell align="right">{row.horario}</TableCell>
                    <TableCell align="right">{row.usuario}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Modal>
    </TemplateHome>
  )
}
