import { Box, Stack, Typography } from "@mui/material";
import { ButtonPortico, TextFieldRHF } from "components";
import { ChangePasswordNextLoginSchema } from "./changePasswordNextLoginSchema";
import { confirmPasswordSchema } from "modules/app/userProfile/components/ConfirmPassword/confirmPasswordSchema";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigate } from "react-router-dom";
import { useAuthSignIn, usePostCredential } from "domains";

export const ChangePasswordNextLogin = ({userId, email}) => {
  const navigate = useNavigate();

  const { control, handleSubmit, getValues } = useForm<ChangePasswordNextLoginSchema>({
    resolver: zodResolver(confirmPasswordSchema),
    mode: "onChange",
  });

  const { isLoading, signIn } = useAuthSignIn({
    onSuccess: () => {
        navigate("home");
    },
  });

  const { postCredential } = usePostCredential({
    onSuccess: () => {
      signIn({
        email,
        password: getValues().password,
        isKeepLogin: true,
      });
    },
  });
  const onChangePassword = ({ password }: ChangePasswordNextLoginSchema) => {
    postCredential({
      userId: userId,
      password,
    });
  };


  return (
    <>
      <Box>
        <Stack spacing={3}>
          <Typography
            variant="h1"
            fontFamily={"Merriweather"}
            color={"#161F28"}
            fontSize={"36px"}
          >
            Criar nova senha
          </Typography>

          <Typography
            variant="h6"
            fontFamily={"Roboto"}
            color={"#75808A"}
            style={{ marginTop: "24px" }}
          >
            A senha deve ter 6 caracteres, uma letra maiúscula, uma letra
            minúscula e um caractere especial.
          </Typography>

          <TextFieldRHF
            control={control}
            type="password"
            name="password"
            placeholder="Nova Senha"
          />


          <TextFieldRHF
            control={control}
            placeholder="Confirmar Nova Senha"
            rules={{ required: "Campos obrigatórios" }}
            type="password"
            name="confirmationPassword"
          />

          <ButtonPortico               
          disabled={isLoading}
          onClick={handleSubmit(onChangePassword)}>
            Confirmar
          </ButtonPortico>
        </Stack>
      </Box>
    </>
  );
};
