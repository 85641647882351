import { IInputProps, InputField } from './InputField/InputField'
import { Box } from '@mui/material'
import { Controller, UseControllerProps, FieldValues } from 'react-hook-form'

export function InputFieldRHF<FormType extends FieldValues>({
  name,
  rules,
  control,
  ...rest
}: {
  name: string
} & IInputProps &
  UseControllerProps<FormType>) {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <Box>
          <InputField
            {...rest}
            isError={!!error?.message}
            value={value}
            onChange={(value) => onChange(value)}
          />
        </Box>
      )}
    />
  )
}
