import { useMutation } from "react-query";
import { MutationOptions } from "infra";

import { rpaService } from "../rpaService";

interface Variables {
  companyId: string;
  userId: string;
}

export function useRestartRPA(options?: MutationOptions<any>) {
  const mutation = useMutation<any, Error, Variables>({
    mutationFn: (variables: Variables) => rpaService.postRestartRPA(variables),
    retry: false,
    onError: (error) => {
      if (options?.onError) {
        options.onError(error.message);
      }
    },
    onSuccess: (authCredentials) => {
      if (options?.onSuccess) {
        options.onSuccess(authCredentials);
      }
    }
  });

  return {
    isLoading: mutation.isLoading,
    restartRPA: (variables: Variables) => mutation.mutate(variables),
    isSuccess: mutation.isSuccess,
    isError: mutation.isError
  };
}
