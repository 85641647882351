import { RegisterOptions } from 'react-hook-form'

import { masks } from '@intraversa-lab/styleguide'

import * as S from './styles'

type TextFieldMasks = 'cpf' | 'number' | 'float' | 'phone' | 'cep' | 'rg'
export interface IInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  isError?: boolean
  mask?: TextFieldMasks
  rules?: RegisterOptions<any, any>
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export const InputField = ({
  isError,
  mask,
  value,
  onChange,
  ...rest
}: IInputProps) => {
  return (
    <S.WrapperInput isError={!!isError}>
      <input
        type="text"
        {...rest}
        value={mask ? masks[mask].input(value) : value}
        onChange={(e) => {
          if (onChange) {
            if (mask) {
              return masks[mask].output(e, onChange)
            }
            return onChange(e)
          }
        }}
      />
      {isError && <span>{isError}</span>}
    </S.WrapperInput>
  )
}
