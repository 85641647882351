import {UserApi} from '../User'

import {authApi} from './authApi'
import {IAuthToken} from './authTypes'

async function authToken(): Promise<IAuthToken> {
  try {
    return await authApi.authToken()
  } catch (error) {
    throw new Error('Token inválido')
  }
}

async function signIn({
  email,
  password,
}: {
  email: string
  password: string
}): Promise<UserApi> {
  try {
    return await authApi.signIn(email, password)
  } catch (error) {
    throw new Error('Email ou senha inválidas')
  }
}

export const authService = {
  authToken,
  signIn,
}
