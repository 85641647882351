import React, { InputHTMLAttributes, useState } from 'react';
import * as S from './styles'
import Eyes from '../../assets/visibility.svg'
import NoEyes from '../../assets/noVisibility.svg'

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  type: string
  id: string
  error?: boolean
}

export const InputPortico = ({ type, id, error, ...rest }: IProps) => {
  const [showText, setShowText] = useState(false)
  const [typeInput, setTypeInput] = useState(type)

  function changeMode() {
    setShowText(!showText)
    setTypeInput(typeInput === 'text' ? 'password' : 'text')
  }

  return (
    <S.ContentInput>
      <S.Wrapper tabIndex={0} data-status={error} error={error}>
        <input type={typeInput} id={id} {...rest} />
        {
          type === 'password' && (
            showText ?
              <img
                src={NoEyes} alt=""
                onClick={changeMode}
              /> :
              <img
                src={Eyes}
                alt=""
                onClick={changeMode}
              />
          )
        }
      </S.Wrapper>
      {
        error &&
        (<S.Error>
          {error}
        </S.Error>)
      }
    </S.ContentInput>
  )
}

