import { TemplateAuth } from 'components'
import { Outlet } from 'react-router-dom'

export const Auth = () => {
  return (
    <TemplateAuth>
      <Outlet />
      {/* //redirect */}
    </TemplateAuth>
  )
}
