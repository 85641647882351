import React from 'react'

export interface ICountdownParams {
  start: number
  setCountComplete: (value: boolean) => void
  restartCount: boolean
}

export const Countdown = ({
  start,
  setCountComplete,
  restartCount,
}: ICountdownParams) => {
  const [secondsLeft, setSecondsLeft] = React.useState(start)

  React.useEffect(() => {
    if (restartCount) {
      setSecondsLeft(start)
      setCountComplete(false)
    }

    if (secondsLeft <= 0) {
      setCountComplete(true)
    }

    const timeout = setTimeout(() => {
      setSecondsLeft(secondsLeft - 1)
    }, 1000)

    return () => clearTimeout(timeout)
  }, [secondsLeft, restartCount])

  return <>{secondsLeft}</>
}
