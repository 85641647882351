import {apiToken, api} from 'api'
import {UserApi} from 'domains/User'
import {endpointsAuth} from 'infra'
import qs from 'qs'
import {config} from 'utils/config'

import {IAuthToken} from './authTypes'

async function authToken() {
  const data = qs.stringify({
    grant_type: 'password',
    password: config.appUserName,
    username: config.appPassword,
  })
  const response = await apiToken.post<IAuthToken>(endpointsAuth.token, data)
  return response.data
}

async function signIn(email: string, password: string): Promise<UserApi> {
  const response = await api.post(endpointsAuth.signIn, {
    email,
    password,
  })
  return response.data
}

export const authApi = {
  authToken,
  signIn,
}
