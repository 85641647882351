import { useState, useEffect, createContext } from 'react'

import { registerInterceptor } from 'api'
import { ICommonEnvironment, ICommonStatus, UserApi, useAuthToken } from 'domains'

import { authStorage } from '../authStorage'
import { AuthCredentialsService, IAuthData, ICommonData } from '../authTypes'

const initialValueAuth = {
  access_token: null,
  user: null,
  common: {
    status: null,
    env: null,
  },
  saveAccessToken: () => null,
  removeAccessToken: () => null,
  saveUserLogged: () => null,
  removeUser: () => null,
  isAuthenticated: false,
  signOut: () => null,
  saveEnvironments: () => null,
  saveStatus: () => null,
}

export const AuthContext =
  createContext<AuthCredentialsService>(initialValueAuth)

export const AuthProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const [authData, setAuthData] = useState<IAuthData>({
    access_token: null,
    user: null,
  })
  const [common, setCommon] = useState<ICommonData>({
    status: null,
    env: null,
  })
  const [isAuthenticated, setIsAuthenticated] = useState(false)

  const serviceToken = useAuthToken({
    onSuccess: (newCredentials) => {
      saveAccessToken(newCredentials.access_token)
    },
  })

  async function startAuthData() {
    const data: IAuthData = {
      access_token: null,
      user: null,
    }

    const accessToken = authStorage.getAccessToken()
    if (accessToken) {
      data.access_token = accessToken
    } else {
      serviceToken.accessToken()
    }

    const userLogged = authStorage.getUserLogged()
    if (accessToken) {
      data.user = userLogged
    }

    const statusData = authStorage.getStatus()
    if (statusData) {
      setCommon((oldState) => ({
        ...oldState,
        status: statusData,
      }))
    }

    const envData = authStorage.getEnvironment()
    if (statusData) {
      setCommon((oldState) => ({
        ...oldState,
        env: envData,
      }))
    }

    setIsAuthenticated(Boolean(userLogged?.id))
    setAuthData(data)
  }

  function saveUserLogged(user: UserApi, isKeepLogin: boolean) {
    if (isKeepLogin) {
      authStorage.setUser(user)
    }
    setIsAuthenticated(Boolean(user?.id))
    setAuthData((oldState) => ({
      ...oldState,
      user,
    }))
  }

  function saveStatus(status: ICommonStatus[]) {
    authStorage.setStatus(status)
    setCommon((old) => ({
      ...old,
      status,
    }))
  }

  function saveEnvironments(environments: ICommonEnvironment[]) {
    authStorage.setEnvironment(environments)
    setCommon((old) => ({
      ...old,
      env: environments,
    }))
  }

  function removeUser() {
    authStorage.removeUser()
    setIsAuthenticated(false)
  }

  function saveAccessToken(accessToken: string) {
    authStorage.setAccessToken(accessToken)
    setAuthData((oldState) => ({
      ...oldState,
      access_token: accessToken,
    }))
  }

  function removeAccessToken() {
    authStorage.removeAccessToken()
  }

  function signOut() {
    removeAccessToken()
    removeUser()
    authStorage.removeStatus()
    authStorage.removeEnvironment()
  }

  useEffect(() => {
    startAuthData()
  }, [])

  useEffect(() => {
    const interceptor = registerInterceptor({
      access_token: authData?.access_token || authStorage.getAccessToken(),
      removeAccessToken,
      saveAccessToken,
    })

    return interceptor
  }, [authData])

  return (
    <AuthContext.Provider
      value={{
        ...authData,
        saveAccessToken,
        removeAccessToken,
        isAuthenticated,
        removeUser,
        saveUserLogged,
        signOut,
        saveEnvironments,
        saveStatus,
        common,
      }}>
      {children}
    </AuthContext.Provider>
  )
}
