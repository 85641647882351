import { Home } from 'modules/app'
import { FormProfile, ListProfile, Profile } from 'modules/app/profile'
import { UserProfile } from 'modules/app/userProfile/userProfile'

export const privateRoutes = [
  { path: 'home', component: Home, isPrivate: true },
  {
    path: 'profile',
    template: Profile,
    isPrivate: true,
    subRoutes: [
      { path: '', component: ListProfile, isPrivate: true },
      {
        path: 'edit/:userId',
        component: () => <FormProfile isMode="edit" />,
        isPrivate: true,
      },
      {
        path: 'create',
        component: () => <FormProfile isMode="create" />,
        isPrivate: true,
      },
    ],
  },
  { path: 'user-profile', component: UserProfile, isPrivate: true },
]
