import {useMutation} from 'react-query'
import {MutationOptions} from 'infra'

import {commonServices} from '../commonServices'

export function useGetEnvs(options?: MutationOptions<any>) {
  const mutation = useMutation<any, Error, any>({
    mutationFn: () => commonServices.getEnvironments(),
    retry: false,
    onError: (error) => {
      if (options?.onError) {
        options.onError(error.message)
      }
    },
    onSuccess: (authCredentials) => {
      if (options?.onSuccess) {
        options.onSuccess(authCredentials)
      }
    },
  })

  return {
    isLoading: mutation.isLoading,
    getEnvs: () => mutation.mutate({}),
    isSuccess: mutation.isSuccess,
    isError: mutation.isError,
  }
}
