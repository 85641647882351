import { useQuery } from 'react-query'
import { QueryKeys } from 'infra'
import { AffilitationService } from '../affiliationService'
import { useAuth } from 'services'

export function useGetAffiliation({
  companyId,
  page = 0,
  offset = 10,
  name,
  sort,

}: {
  companyId: string
  page: number
  offset: number
  name: string
  sort: string
}) {
  const { user } = useAuth();


  const { data, isLoading, isError, refetch, isFetching } = useQuery({
    queryKey: [QueryKeys.getAffiliation, { companyId, name }],
    queryFn: () => {
      return AffilitationService.getAffiliation({
        companyId,
        page,
        offset,
        name,
        sort
      })
    },
    staleTime: 1000 * 30, // 10 seconds
  })

  return {
    affiliation: data?.filter(item => item.id !== user.id),
    isLoading,
    isFetching,
    isError,
    refetch,
  }
}


export function useGetAffiliationByCompanyByUserId({ companyId, userId, enabled }: {
  companyId: string
  userId: string,
  enabled?: boolean
}) {
  const { data, isLoading, isError, refetch, isFetching } = useQuery({
    queryKey: [QueryKeys.getAffiliationById, { companyId, userId }],
    queryFn: () => {
      return AffilitationService.getAffiliationByCompanyByUserId({ companyId, userId })
    },
    staleTime: 1000 * 30, // 10 seconds
    enabled
  })

  return {
    affiliation: data,
    isLoading,
    isFetching,
    isError,
    refetch,
  }
}