import {TemplateHome} from 'components/template/TemplateHome/TemplateHome'
import {Outlet} from 'react-router-dom'

export const Profile = () => {
  return (
    <TemplateHome>
      <Outlet />
    </TemplateHome>
  )
}
