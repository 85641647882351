import styled from 'styled-components'

export const Wrapper = styled.form`
  display: flex;
  input[type='radio'] {
    display: none;

    &:checked + label {
      color: #6d42e1;
      border-color: #6d42e1;
    }
  }

  label {
    text-align: center;
    background-color: transparent;
    border: 1px solid #c8d0d9;
    padding: 10px 16px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    font-family: Roboto;
    color: #161f28;
    transition: background-color 0.3s ease;

    &:active {
      background-color: #dfe4e9 !important;
    }

    &:hover {
      color: #161f28;
      background-color: #eff2f4;
    }
  }

  .firstLabel {
    border-radius: 8px 0px 0px 8px;
    padding-left: 32px;
  }

  label:last-child {
    border-radius: 0px 8px 8px 0px;
    padding-right: 32px;
  }

  @media (max-width: 922px) {
    width:100%;

    label {
      width: 100%;
    }
  }
`
