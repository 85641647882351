import React, {ButtonHTMLAttributes} from 'react'

import * as S from './styles'

interface IbuttonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'outlined' | 'filled'
  children: React.ReactNode
}

export const ButtonPortico = ({variant, children, ...rest}: IbuttonProps) => {
  return (
    <S.WrapperButton variant={variant ?? 'filled'} {...rest}>
      {children}
    </S.WrapperButton>
  )
}
