import {useAuth} from 'services'

export const useCommon = () => {
  const {common} = useAuth()

  function getStatusByID(statusId: string) {
    if (common.status) {
      return common.status.find((item) => item.id === statusId)
    }
  }

  function checkStatusActive(statusId: string) {
    if (common.status) {
      return (
        common.status
          .find((item) => String(item.id) === String(statusId))
          ?.name?.toLocaleUpperCase() === 'ATIVO' || false
      )
    }
    return false
  }

  function getEnvironmentsByID(envId: string) {
    if (common.env) {
      return common.env.find((item) => item.id === envId)
    }
  }

  return {
    getStatusByID,
    getEnvironmentsByID,
    checkStatusActive,
  }
}
