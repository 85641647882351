import { useMutation } from 'react-query'
import { MutationOptions } from 'infra'

import { userService } from '../userService'
import { IResponseDeleteUser } from '../userTypes'
import { toast } from '@intraversa-lab/styleguide'



export function useDeleteUser(
  options?: MutationOptions<IResponseDeleteUser>,
) {
  const mutation = useMutation<IResponseDeleteUser, Error, string>({
    mutationFn: (id: string) =>
      userService.deleteUser(id),
    retry: false,
    onError: (error) => {
      if (options?.onError) {
        options.onError(error.message)
      }
    },
    onSuccess: (data) => {
      toast({
        type: 'success',
        text: 'Perfil Excluído',
        description: 'Perfil excluído com sucesso',
      })

      if (options?.onSuccess) {
        options.onSuccess(data)
      }
    },
  })

  return {
    isLoading: mutation.isLoading,
    deleteUser: (id: string) => mutation.mutate(id),
    isSuccess: mutation.isSuccess,
    isError: mutation.isError,
  }
}
