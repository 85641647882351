import React, {useState} from 'react'

import * as S from './styles'

interface IProps {
  onChange: (v: number) => void
}

const radioOptions = [
  {id: 1, label: 'Todas', value: 'Todas'},
  {id: 2, label: 'Construídas', value: 'Construídas'},
  {id: 3, label: 'Cadastradas', value: 'Cadastradas'},
]

export const TabButton = ({onChange}: IProps) => {
  const [selected, setSelected] = useState('Todas')

  function handleRadioChange(value: string, id: number) {
    setSelected(value)
    onChange(id)
  }

  return (
    <S.Wrapper>
      {radioOptions.map((option) => (
        <React.Fragment key={option.value}>
          <input
            id={option.value}
            type="radio"
            name="tabButton"
            value={option.value}
            onChange={() => handleRadioChange(option.value, option.id)}
            checked={selected === option.value}
          />
          <label
            htmlFor={option.value}
            className={option.id === 1 ? 'firstLabel' : 'lastLabel'}>
            {option.label}
          </label>
        </React.Fragment>
      ))}
    </S.Wrapper>
  )
}
