import { z } from 'zod'

// const roleSchema = z.object({
//   label: z.string(),
//   value: z.string()
// })

export const profileSchema = z.object({
  nomeCompleto: z.string().min(1, 'Campo Obrigatório'),
  email: z.string().email('Campo Obrigatório'),
  cargo: z.string(),
  roles: z.string() 
}).refine(data => data.roles !== undefined, { message: 'Campo Obrigatório' })

export type ProfileSchema = z.infer<typeof profileSchema>
