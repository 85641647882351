import axios from 'axios'
import {config} from 'utils/config'

const credentials = `${config.appClientId}:${config.appSecret}`
const base64Credentials = btoa(credentials)
const basicAuthHeader = `Basic ${base64Credentials}`

export const apiToken = axios.create({
  baseURL: config.baseUrl,
  headers: {
    accept: '*/*',
    'Content-Type': 'application/x-www-form-urlencoded',
    Authorization: basicAuthHeader,
  },
})
