import { toast } from '@intraversa-lab/styleguide'
import { DefaultMessageApi } from 'types'

import { userApi } from './userApi'
import {
  IChangePassword,
  IChangePasswordAPI,
  IChangePasswordCodeRequest,
  IConfirmationCodeApi,
  IUserComplete,
  IValidationEmailAPI,
} from './userTypes'

async function getValidationEmail({
  email,
}: {
  email: string
}): Promise<IValidationEmailAPI> {
  try {
    const respose = await userApi.getValidationEmail(email)
    return respose
  } catch (error) {
    throw new Error('Erro ao validar email')
  }
}

async function postSendCode({
  userId,
}: {
  userId: string
}): Promise<DefaultMessageApi> {
  try {
    const response = await userApi.postSendCode(userId)
    return response
  } catch (error) {
    throw new Error('Erro ao enviar codigo')
  }
}

async function postConfirmationCode({
  userId,
  code,
}: {
  userId: string
  code: string
}): Promise<IConfirmationCodeApi> {
  try {
    const respose = await userApi.postConfirmationCode(userId, code)
    return respose
  } catch (error) {
    throw new Error('Erro ao validar email')
  }
}

async function postChangePasswordCode(
  payload: IChangePasswordCodeRequest,
): Promise<IChangePasswordAPI> {
  try {
    const respose = await userApi.postChangePasswordCode(payload)
    toast({
      type: 'success',
      text: 'Senha atualizada com sucesso.',
    })
    return respose
  } catch (error) {
    toast({
      type: 'error',
      text: 'Erro no servidor.',
      description: 'tente novamente em instantes.',
    })
    throw new Error('Erro ao alterar senha')
  }
}


async function putChangePassword(
  userCredentialId: string,
  payload: IChangePassword,
): Promise<IChangePasswordAPI> {
  try {
    const respose = await userApi.putChangePassword(userCredentialId, payload)
    return respose
  } catch (error) {
    toast({
      type: 'error',
      text: 'Erro no servidor.',
      description: 'tente novamente em instantes.',
    })
    throw new Error('Erro ao alterar senha')
  }
}

async function postCredential(
  payload: IChangePassword,
): Promise<IChangePasswordAPI> {
  try {
    const respose = await userApi.postCredential(payload)

    return respose
  } catch (error) {
    toast({
      type: 'error',
      text: 'Erro no servidor.',
      description: 'tente novamente em instantes.',
    })
    throw new Error('Erro ao criar senha')
  }
}


async function postUserComplete(data: IUserComplete): Promise<any> {
  try {
    const respose = await userApi.postUserComplete(data)
    return respose
  } catch (error) {
    toast({
      type: 'error',
      text: 'User error.',
      description: 'tente novamente.',
    })
    throw new Error('Erro ao Salvar user')
  }
}

async function putUserComplete(userId: string, data: IUserComplete): Promise<any> {
  try {
    const respose = await userApi.putUserComplete(userId, data)
    return respose
  } catch (error) {
    toast({
      type: 'error',
      text: 'User error.',
      description: 'tente novamente.',
    })
    throw new Error('Erro ao Salvar user')
  }
}

async function deleteUser(id: string): Promise<any> {
  try {
    const respose = await userApi.deleteUser(id)
    return respose
  } catch (error) {
    toast({
      type: 'error',
      text: 'User error.',
      description: 'tente novamente.',
    })
    throw new Error('Erro ao excluir usuário')
  }
}

export const userService = {
  getValidationEmail,
  postSendCode,
  postConfirmationCode,
  postChangePasswordCode,
  putChangePassword,
  postUserComplete,
  putUserComplete,
  postCredential,
  deleteUser
}
