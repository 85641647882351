import {useContext} from 'react'

import {AuthCredentialsService} from './authTypes'
import {AuthContext} from './Provider/AuthProvider'

export function useAuth(): AuthCredentialsService {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error('useAuth should be used within a AuthProvider')
  }
  return context
}
