import CloseIcon from '@mui/icons-material/Close'
import styled from 'styled-components'

export const Wrapper = styled.div``

export const WrapperModal = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`

type ModalProps = {
  maxWidth: number
}

export const ModalContent = styled.div<ModalProps>`
  max-width: ${(props) => props.maxWidth + 'px'};
  width: 100%;
  background-color: #fff;
  padding: 80px 56px 56px 56px;
  border-top: 4px solid #161f28;
  position: absolute;
  border-radius: 4px;
  z-index: 2;

  @media (max-width: 922px) {
    height: 100%;
    padding: 100px 16px 16px 16px;
  }

`

export const ContentClose = styled.div`
  position: absolute;
  top: 28px;
  right: 28px;
  cursor: pointer;
  @media (max-width: 922px) {
    right: auto;
  }

`

export const Overlay = styled.div`
  height: 100vh;
  width: 100vw;
`

interface IModalProps {
  onClose: () => void
  isOpen: boolean
  children?: React.ReactNode
  maxWidth?: number
}

export const Modal = ({isOpen, onClose, maxWidth, children}: IModalProps) => {
  return (
    <Wrapper>
      {isOpen && (
        <WrapperModal>
          <ModalContent maxWidth={maxWidth ?? 530}>
            <ContentClose onClick={() => onClose()}>
              <CloseIcon style={{
                fontSize: '32px'
              }}/>
            </ContentClose>
            {children}
          </ModalContent>
          <Overlay onClick={() => onClose()} />
        </WrapperModal>
      )}
    </Wrapper>
  )
}
