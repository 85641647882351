


export const IconTrash = () => {
  return (
    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_2433_10367" maskUnits="userSpaceOnUse" x="0" y="0" width="18" height="19">
        <rect y="0.5" width="18" height="18" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_2433_10367)">
        <path d="M4.89375 16.25C4.58438 16.25 4.31953 16.1398 4.09922 15.9195C3.87891 15.6992 3.76875 15.4344 3.76875 15.125V4.4375H3V3.3125H6.525V2.75H11.475V3.3125H15V4.4375H14.2313V15.125C14.2313 15.425 14.1188 15.6875 13.8938 15.9125C13.6688 16.1375 13.4063 16.25 13.1063 16.25H4.89375ZM13.1063 4.4375H4.89375V15.125H13.1063V4.4375ZM6.88125 13.5125H8.00625V6.03125H6.88125V13.5125ZM9.99375 13.5125H11.1187V6.03125H9.99375V13.5125Z" fill="#161F28" />
        <path d="M4.89375 16.25C4.58438 16.25 4.31953 16.1398 4.09922 15.9195C3.87891 15.6992 3.76875 15.4344 3.76875 15.125V4.4375H3V3.3125H6.525V2.75H11.475V3.3125H15V4.4375H14.2313V15.125C14.2313 15.425 14.1188 15.6875 13.8938 15.9125C13.6688 16.1375 13.4063 16.25 13.1063 16.25H4.89375ZM13.1063 4.4375H4.89375V15.125H13.1063V4.4375ZM6.88125 13.5125H8.00625V6.03125H6.88125V13.5125ZM9.99375 13.5125H11.1187V6.03125H9.99375V13.5125Z" fill="black" fill-opacity="0.2" />
        <path d="M4.89375 16.25C4.58438 16.25 4.31953 16.1398 4.09922 15.9195C3.87891 15.6992 3.76875 15.4344 3.76875 15.125V4.4375H3V3.3125H6.525V2.75H11.475V3.3125H15V4.4375H14.2313V15.125C14.2313 15.425 14.1188 15.6875 13.8938 15.9125C13.6688 16.1375 13.4063 16.25 13.1063 16.25H4.89375ZM13.1063 4.4375H4.89375V15.125H13.1063V4.4375ZM6.88125 13.5125H8.00625V6.03125H6.88125V13.5125ZM9.99375 13.5125H11.1187V6.03125H9.99375V13.5125Z" fill="black" fill-opacity="0.2" />
        <path d="M4.89375 16.25C4.58438 16.25 4.31953 16.1398 4.09922 15.9195C3.87891 15.6992 3.76875 15.4344 3.76875 15.125V4.4375H3V3.3125H6.525V2.75H11.475V3.3125H15V4.4375H14.2313V15.125C14.2313 15.425 14.1188 15.6875 13.8938 15.9125C13.6688 16.1375 13.4063 16.25 13.1063 16.25H4.89375ZM13.1063 4.4375H4.89375V15.125H13.1063V4.4375ZM6.88125 13.5125H8.00625V6.03125H6.88125V13.5125ZM9.99375 13.5125H11.1187V6.03125H9.99375V13.5125Z" fill="black" fill-opacity="0.2" />
        <path d="M4.89375 16.25C4.58438 16.25 4.31953 16.1398 4.09922 15.9195C3.87891 15.6992 3.76875 15.4344 3.76875 15.125V4.4375H3V3.3125H6.525V2.75H11.475V3.3125H15V4.4375H14.2313V15.125C14.2313 15.425 14.1188 15.6875 13.8938 15.9125C13.6688 16.1375 13.4063 16.25 13.1063 16.25H4.89375ZM13.1063 4.4375H4.89375V15.125H13.1063V4.4375ZM6.88125 13.5125H8.00625V6.03125H6.88125V13.5125ZM9.99375 13.5125H11.1187V6.03125H9.99375V13.5125Z" fill="black" fill-opacity="0.2" />
      </g>
    </svg>

  )
}