import { useCallback, useState } from 'react'

import { IChangePasswordCodeRequest } from 'domains'
import { useNavigate } from 'react-router-dom'

import { ForgotPassword, NewPassword, VerificationCode } from './components'

enum CHANGE_PASSWORD {
  VALIDATE_EMAIL,
  SEND_CODE,
  CHANGE_PASSWORD,
}

interface FormData extends IChangePasswordCodeRequest {
  email: string
}

export interface IChangePasswordStepsProps {
  handleNext: () => void
  handleChangeFormData: (name: keyof FormData, newValue: string) => void
  formDataChangePassword: FormData
}

export const ChangePassword = () => {
  const navigate = useNavigate()
  const [activedStep, setActivedStep] = useState(
    CHANGE_PASSWORD.VALIDATE_EMAIL,
  )
  const [formDataChangePassword, setFormDataChangePasword] = useState<FormData>(
    {
      codeId: '',
      password: '',
      userId: '',
      email: '',
    },
  )

  const handleNext = () => {
    if (activedStep === CHANGE_PASSWORD.CHANGE_PASSWORD) return navigate('/')

    setActivedStep((old) => old + 1)
  }

  const handleChangeFormData = useCallback(
    (name: keyof FormData, newValue: string) => {
      setFormDataChangePasword((old) => ({
        ...old,
        [name]: newValue,
      }))
    },
    [],
  )

  const steps = (
    props: IChangePasswordStepsProps,
  ): { [key in CHANGE_PASSWORD]: JSX.Element } => ({
    [CHANGE_PASSWORD.VALIDATE_EMAIL]: <ForgotPassword {...props} />,
    [CHANGE_PASSWORD.SEND_CODE]: <VerificationCode {...props} />,
    [CHANGE_PASSWORD.CHANGE_PASSWORD]: <NewPassword {...props} />,
  })

  return (
    <>
      {
        steps({
          handleNext,
          handleChangeFormData,
          formDataChangePassword,
        })[activedStep]
      }
    </>
  )
}
