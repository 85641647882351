import {Tuser} from './types'

export const usersMock: Tuser[] = [
  {
    id: 1,
    name: 'Mariana',
    email: 'marianacamargo@intraversa.com',
    cargo: 'Designer',
    ultimoAcesso: '7 dias',
    permissao: 'Administrador(Criador)',
  },
  {
    id: 2,
    name: 'Ney',
    email: 'ney@intraversa.com',
    cargo: 'Desenvolvedor',
    ultimoAcesso: '3 meses',
    permissao: 'Administrador(Criador)',
  },
  {
    id: 2,
    name: 'Odailson',
    email: 'oda@intraversa.com',
    cargo: 'Desenvolvedor',
    ultimoAcesso: '3 semanas',
    permissao: 'Administrador(Criador)',
  },
]
