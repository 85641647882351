import styled from "styled-components";

type InputToken = {
  error: boolean
}


export const Wrapper = styled.div<InputToken>`

  input {
    background-color: transparent !important;
    border: 1px solid  ${(props) => (props.error ? '#FF4B38' : '#59636B')} !important;
    color: #161F28 !important;
    
    &:focus{
      border: 1px solid #6D42E1 !important;
    }

    &:active {
      border: 1px solid #59636B !important;
    }

    &:hover {
      border: 1px solid #929EA8 !important;
    }
  }
`