
export const IconOrdernar = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_632_26745" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
        <path d="M0 20L-8.74228e-07 0L20 -8.74228e-07L20 20L0 20Z" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_632_26745)">
        <path d="M17.2102 14.0383L13.332 17.9165L9.45378 14.0383L10.332 13.1473L12.707 15.5223L12.707 2.91654L13.9569 2.91654L13.9569 15.5223L16.332 13.1473L17.2102 14.0383ZM10.5435 5.9614L9.6653 6.8524L7.29028 4.4774L7.29028 17.0832L6.04032 17.0832L6.04032 4.4774L3.6653 6.8524L2.78711 5.9614L6.6653 2.08321L10.5435 5.9614Z" fill="#161F28" />
        <path d="M17.2102 14.0383L13.332 17.9165L9.45378 14.0383L10.332 13.1473L12.707 15.5223L12.707 2.91654L13.9569 2.91654L13.9569 15.5223L16.332 13.1473L17.2102 14.0383ZM10.5435 5.9614L9.6653 6.8524L7.29028 4.4774L7.29028 17.0832L6.04032 17.0832L6.04032 4.4774L3.6653 6.8524L2.78711 5.9614L6.6653 2.08321L10.5435 5.9614Z" fill="black" fill-opacity="0.2" />
        <path d="M17.2102 14.0383L13.332 17.9165L9.45378 14.0383L10.332 13.1473L12.707 15.5223L12.707 2.91654L13.9569 2.91654L13.9569 15.5223L16.332 13.1473L17.2102 14.0383ZM10.5435 5.9614L9.6653 6.8524L7.29028 4.4774L7.29028 17.0832L6.04032 17.0832L6.04032 4.4774L3.6653 6.8524L2.78711 5.9614L6.6653 2.08321L10.5435 5.9614Z" fill="black" fill-opacity="0.2" />
        <path d="M17.2102 14.0383L13.332 17.9165L9.45378 14.0383L10.332 13.1473L12.707 15.5223L12.707 2.91654L13.9569 2.91654L13.9569 15.5223L16.332 13.1473L17.2102 14.0383ZM10.5435 5.9614L9.6653 6.8524L7.29028 4.4774L7.29028 17.0832L6.04032 17.0832L6.04032 4.4774L3.6653 6.8524L2.78711 5.9614L6.6653 2.08321L10.5435 5.9614Z" fill="black" fill-opacity="0.2" />
        <path d="M17.2102 14.0383L13.332 17.9165L9.45378 14.0383L10.332 13.1473L12.707 15.5223L12.707 2.91654L13.9569 2.91654L13.9569 15.5223L16.332 13.1473L17.2102 14.0383ZM10.5435 5.9614L9.6653 6.8524L7.29028 4.4774L7.29028 17.0832L6.04032 17.0832L6.04032 4.4774L3.6653 6.8524L2.78711 5.9614L6.6653 2.08321L10.5435 5.9614Z" fill="black" fill-opacity="0.2" />
      </g>
    </svg>
  )
}