import { useCallback, useState } from 'react'

import { zodResolver } from '@hookform/resolvers/zod'
import { Button } from '@intraversa-lab/styleguide'
import { Box, Stack, Typography } from '@mui/material'
import { ButtonPortico, TextFieldRHF } from 'components'
import { useGetValidationEmail } from 'domains'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { IChangePasswordStepsProps } from '../../ChangePassword'

import { ForgotPaswordSchema, forgotPasswordSchema } from './forgotPasswordSchema'

export const ForgotPassword = ({
  handleNext,
  handleChangeFormData,
}: IChangePasswordStepsProps) => {
  const [emailError, setEmailError] = useState<String | null>()

  const navigate = useNavigate()
  const { isLoading, validationEmail } = useGetValidationEmail({
    onSuccess: (data) => {
      handleChangeFormData('userId', data.id)
      handleChangeFormData('email', data.email)
      handleNext()
    },
    onError: () => {
      setEmailError('Não foi possível encontrar sua conta')
    },
  })
  const { control, handleSubmit } = useForm<ForgotPaswordSchema>()

  const onValidateCode = useCallback(
    ({ email }: ForgotPaswordSchema) => {
      validationEmail({
        email,
      })
    },
    [validationEmail],
  )

  return (
    <>
      <Box>
        <Stack spacing={3}>
          <Typography variant="h1" fontFamily={'Merriweather'}>
            Esqueceu a senha?
          </Typography>
          <Typography variant="h6">
            Insira o endereço de e-mail que você utilizou para criar sua conta.
            Mandaremos um código para resetar a senha.
          </Typography>

          <TextFieldRHF control={control} name="email" id="EmailForgotPassword" placeholder="Email" error={!!emailError} onFocus={() => setEmailError('')} />
          {emailError && (
            <p
              id="EmailError"
              style={{
                color: '#FF4B38',
                fontSize: '12px',
                marginTop: '10px',
              }}>
              {emailError}
            </p>
          )}

          <ButtonPortico id="Confirmar" onClick={handleSubmit(onValidateCode)}>
            Confirmar
          </ButtonPortico>
        </Stack>

        <Box display={'flex'} justifyContent={'center'}>
          <Button
            id="RetornarAoLogin"
            variant="text"
            arround
            style={{
              marginTop: 48,
              textTransform: 'none',
              padding: '12px 24px',
              fontSize: '16px',
            }}
            onClick={() => navigate('/')}
            disabled={isLoading}
            sx={{
              '&:hover': {
                backgroundColor: '#E2D9F9',
                color: '#5735B4',
              },
            }}>
            Retornar ao Login
          </Button>
        </Box>
      </Box>
    </>
  )
}
