import { Box } from '@mui/material'
import { styled } from 'styled-components'

export const HeaderFilter = styled(Box)`
  display: flex;
  align-items: center;
  gap: 32px;
  margin-bottom: 32px;
  width: 100%;
  /* background-color: red; */

  button {
    text-transform: capitalize;
  }

  .filters {
    display: flex;
    flex: 1;
    gap: 32px;

    .inputSearchMobile {
      width: 100%;
    }
    

    @media (max-width: 650px) {
      gap: 0;
    }
  }

  @media (max-width: 922px) {
    flex-direction: column;
    width: 100%;
    .inputSearchMobile {
      width: 15%;
    }

    .filters {
      width: 100%;

    }
  }
`

export const WrapperContent = styled.div`
  margin-top: 32px;
  .content_header{
    padding: 0px 32px 32px 32px;
    border-bottom: 1px solid #DFE4E9;
    @media (max-width: 922px) {
      padding: 0px 16px 24px 16px;
      
    }
  }
  .content_body {
    padding: 32px;      

    @media (max-width: 922px) {
      padding: 32px 16px 32px 16px;      
    }
  }
`

export const NotFound = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    color: #999;
    font-weight: 500;
  }

`
