import {useMutation} from 'react-query'
import {MutationOptions} from 'infra'
import {useAuth} from 'services'

import {authService} from '../authService'
import {IAuthToken} from '../authTypes'

export function useAuthToken(options?: MutationOptions<IAuthToken>) {
  const {saveAccessToken} = useAuth()
  const mutation = useMutation<IAuthToken, Error>({
    mutationFn: () => authService.authToken(),
    retry: false,
    onError: (error) => {
      if (options?.onError) {
        options.onError(error.message)
      }
    },
    onSuccess: (authCredentials) => {
      if (options?.onSuccess) {
        options.onSuccess(authCredentials)
      }
      saveAccessToken(authCredentials.access_token)
    },
  })

  return {
    isLoading: mutation.isLoading,
    accessToken: () => mutation.mutate(),
    isSuccess: mutation.isSuccess,
    isError: mutation.isError,
  }
}
