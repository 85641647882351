import styled, {css} from 'styled-components'

export const Container = styled.div`
  font-family: 'Roboto';
  width: 100%;
  position: relative;

  /* input {
    height: 42px;
  } */

  .css-qbdosj-Input {
    margin: 0px;
    padding: 0px;
  }

  .css-1fdsijx-ValueContainer {
    padding: 0px;
  }

  .css-1u9des2-indicatorSeparator {
    display: none;
  }

  .css-894a34-indicatorSeparator {
    display: none;
  }

  .css-lm8j94-menu,
  .css-gfh3go-menu {
    z-index: 2;
  }

  svg {
    cursor: pointer;
  }
`

export const Required = styled.span<{$isRequired: boolean}>`
  color: rgba(237, 0, 0, 1);
  display: ${({$isRequired}) => ($isRequired ? 'block' : 'none')};
`
