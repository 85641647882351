export const endpointsAuth = {
  token: '/public/auth/token',
  signIn: '/user/login',
  getEmailValidation: '/user/email?value=:email',
  postChangePassword: '/user/credential/change',
  putChangePassword: '/user/credential/:userCredentialId',
  postCredential: '/user/credential',
  postSendCode: '/messaging/user/:userId/send-code',
  postConfirmationCode: '/messaging/user/:userId/confirm-code',
  postUserComplete: 'user/complete',
  putUserComplete: 'user/complete/:userId',
} as const

export const endpointsRPA = {
  getCompanyRPA: '/rpa/company/:companyId',
  postStartRPA: '/rpa/start/:companyId?userId=:userId',
  postRestartRPA: '/rpa/restart/:companyId?userId=:userId',
  postStopRPA: '/rpa/stop/:companyId?userId=:userId',
  getHistoryRPA: '/rpa/:rpaId/history',
} as const

export const endpointsAffiliation = {
  getAffiliation: '/affiliation/company/:companyId',
  getAffiliationByCompanyByUserId: '/affiliation/company/:companyId/user/:userId',
  deleteAffiliation: 'affiliation/:id',

} as const

export const endpointsRole = {
  getRole: '/role',
}

export const endpointsCommon = {
  getStatus: '/status',
  getEnv: '/env',
} as const
