import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { Button, ButtonPortico } from '@intraversa-lab/styleguide'
import { Box, Stack, Typography } from '@mui/material'
import { InputToken } from 'components/inputToken/InputToken'
import { usePostConfirmationCode, usePostSendCode } from 'domains'
import { useNavigate } from 'react-router-dom'
import { hideEmail } from 'utils/hideEmail'

import { IChangePasswordStepsProps, REDEFINE_PASSWORD } from '../../RedefinePassword'

import { Countdown } from './Countdown'
import * as S from "../../styles";

export const VerificationCode = ({
  handleNext,
  handleChangeFormData,
  formDataChangePassword,
}: IChangePasswordStepsProps) => {
  const navigate = useNavigate()
  const isFirst = useRef(false)
  const { sendCode } = usePostSendCode()
  const [codeError, setCodeError] = useState("");
  const { isLoading: isLoadingConfirmation, confirmationCode } =
    usePostConfirmationCode({
      onSuccess: (data) => {
        handleChangeFormData('codeId', data.id)
        handleNext(REDEFINE_PASSWORD.CONFIRM_PASSWORD)
      },
      onError() {
        setCodeError("Código inválido")
      },
    })

  const [value, setValue] = useState('')
  const [countComplete, setCountComplete] = useState(false)
  const [restartCount, setRestartCount] = useState(false)

  const isActivedButton = useMemo(() => {
    return value.length === 6
  }, [value])

  const onConfirmationCode = useCallback(() => {
    confirmationCode({
      code: value,
      userId: formDataChangePassword.userId,
    })
  }, [formDataChangePassword, confirmationCode])

  const handleSendCode = () => {
    setRestartCount(true)
    setCountComplete(false)
    sendCode({
      userId: formDataChangePassword.userId,
    })
  }

  useEffect(() => {
    if (!isFirst.current) {
      isFirst.current = true

      sendCode({
        userId: formDataChangePassword.userId,
      })
    }
  }, [])

  return (
    <>
      <Box>
        <Stack spacing={3}>
          <Typography
            variant="h1"
            fontFamily={'Merriweather'}
            color={'#161F28'}
            marginTop={'80px'}
            fontSize={'36px'}>
            Código de Verificação
          </Typography>
          <Typography
            variant="h6"
            fontFamily={'Roboto'}
            color={'#75808A'}
            style={{ marginTop: '24px' }}>
            Insira o código enviado para o email{' '}
            {hideEmail(formDataChangePassword.email)}
          </Typography>
          <Box
            display="flex"
            justifyContent={'center'}
            width={'100%'}
            style={{ marginTop: '24px' }}>
            <InputToken numDigits={6} setValues={(v: string) => setValue(v)} error={!!codeError}/>
          </Box>
          {codeError && (
            <p
              style={{
                color: '#FF4B38',
                fontSize: '12px',
                marginTop: '10px',
              }}>
              {codeError}
            </p>
          )}
          <S.ContainerSendCode>
            {countComplete ? (
              <div className='text-code'>
                <p style={{ color: '#75808A', fontWeight: '400', margin: '0' }}>
                  Não recebeu o código?
                </p>

                <Button
                  variant="text"
                  arround
                  style={{
                    margin: '0',
                    fontWeight: '500',
                    fontFamily: 'Roboto',
                    textTransform: 'none',
                    padding: '12px 24px',
                    fontSize: '16px',
                  }}
                  sx={{
                    '&:hover': {
                      backgroundColor: '#E2D9F9 !important',
                      color: '#5735B4',
                    },
                  }}
                  onClick={handleSendCode}>
                  Clique para reenviar
                </Button>
              </div>
            ) : (
              <p style={{ color: '#75808A', fontWeight: '400' }}>
                Enviar novamente em{' '}
                <Countdown
                  start={60}
                  restartCount={restartCount}
                  setCountComplete={(value) => {
                    setCountComplete(value)
                    setRestartCount(false)
                  }}
                />
                s
              </p>
            )}
          </S.ContainerSendCode>
          <S.footerModal>
            <ButtonPortico
              onClick={onConfirmationCode}
              disabled={!isActivedButton}
            >
              Confirmar
            </ButtonPortico>
          </S.footerModal>
        </Stack>
      </Box>
    </>
  )
}
