import { api } from "api";
import { IRole } from "./roleTypes";
import { endpointsRole } from "infra/endpoints";


async function getRole({
  page,
  offset,
  sort
}: {
  page: number,
  offset: number,
  sort?: string
}): Promise<IRole[]> {
  const response = await api.get(
    endpointsRole.getRole,
    {
      params: {
        page, offset, sort
      }
    }
  )
  return response.data
}


export const roleApi = {
  getRole
}