import styled from 'styled-components'

type TInput = {
  isError: boolean
}

export const WrapperInput = styled.div<TInput>`
  width: 100%;

  input {
    width: 100%;
    background-color: #eff2f4;

    border: 1px solid ${(props) => (props.isError ? '#FF4B38' : 'transparent')};
    outline: none;
    padding: 16px 24px;
    border-radius: 16px;
    font-size: 16px;

    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    color: ${(props) => (props.isError ? '#FF4B38' : '#161f28')};

    &:placeholder {
      color: #929ea8;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
    }

    &:hover {
      border: 1px solid ${(props) => (props.isError ? '#FF4B38' : '#161f28')};
      background-color: #f9f9f9;
    }

    &:focus {
      background-color: #f9f9f9;
      border: 1px solid ${(props) => (props.isError ? '#FF4B38' : '#161f28')};
    }
  }

  span {
    display: block;
    color: #ff4b38;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    margin-top: 8px;
    margin-left: 16px;
  }
`
