import {useMutation} from 'react-query'
import {MutationOptions} from 'infra'
import {DefaultMessageApi} from 'types'

import {userService} from '../userService'
import {IChangePasswordAPI, IChangePasswordCodeRequest} from '../userTypes'

type Variables = IChangePasswordCodeRequest

export function usePostChangePassword(
  options?: MutationOptions<IChangePasswordAPI>,
) {
  const mutation = useMutation<IChangePasswordAPI, Error, Variables>({
    mutationFn: (variables: Variables) =>
      userService.postChangePasswordCode(variables),
    retry: false,
    onError: (error) => {
      if (options?.onError) {
        options.onError(error.message)
      }
    },
    onSuccess: (authCredentials) => {
      if (options?.onSuccess) {
        options.onSuccess(authCredentials)
      }
    },
  })

  return {
    isLoading: mutation.isLoading,
    changePassword: (variables: Variables) => mutation.mutate(variables),
    isSuccess: mutation.isSuccess,
    isError: mutation.isError,
  }
}
