import { api } from "api";
import { endpointsRPA } from "infra";

import { ICompanyRPA, IGetRPAHistory } from "./rpaTypes";

async function getCompanyRPA({
  companyId,
  page = 0,
  offset = 10,
  name,
  active,
  sort
}: {
  companyId: string;
  page: number;
  offset: number;
  name: string;
  active?: boolean;
  sort: string;
}): Promise<ICompanyRPA[]> {
  const response = await api.get(
    endpointsRPA.getCompanyRPA.replace(":companyId", companyId),
    {
      params: {
        page,
        offset,
        name,
        active,
        sort
      }
    }
  );
  return response.data;
}

async function getHistoryRPA(rpaId: string): Promise<IGetRPAHistory[]> {
  const response = await api.get(
    endpointsRPA.getHistoryRPA.replace(":rpaId", rpaId)
  );
  return response.data;
}

async function postStartRPA({
  companyId,
  userId
}: {
  companyId: string;
  userId: string;
}): Promise<any> {
  const response = await api.post(
    endpointsRPA.postStartRPA
      .replace(":companyId", companyId)
      .replace(":userId", String(userId))
  );
  return response.data;
}

async function postRestartRPA({
  companyId,
  userId
}: {
  companyId: string;
  userId: string;
}): Promise<any> {
  const response = await api.post(
    endpointsRPA.postRestartRPA
      .replace(":companyId", companyId)
      .replace(":userId", String(userId))
  );
  return response.data;
}

async function postStopRPA({
  companyId,
  userId
}: {
  companyId: string;
  userId: string;
}): Promise<any> {
  const response = await api.post(
    endpointsRPA.postStopRPA
      .replace(":companyId", companyId)
      .replace(":userId", String(userId))
  );
  return response.data;
}

export const rpaApi = {
  getCompanyRPA,
  postStartRPA,
  postRestartRPA,
  postStopRPA,
  getHistoryRPA
};
