import { useCallback, useState } from 'react'

import { Box, Stack, Typography } from '@mui/material'
import { ButtonPortico, TextFieldRHF } from 'components'
import { useGetValidationEmail } from 'domains'
import { useForm } from 'react-hook-form'


import { ForgotPaswordSchema, forgotPasswordSchema } from './forgotPasswordSchema'
import { IChangePasswordStepsProps, REDEFINE_PASSWORD } from '../../RedefinePassword'
import * as S from "../../styles";

export const ForgotPassword = ({
  handleNext,
  handleChangeFormData,
}: IChangePasswordStepsProps) => {
  const [emailError, setEmailError] = useState<String | null>()

  const { validationEmail } = useGetValidationEmail({
    onSuccess: (data) => {
      handleChangeFormData('userId', data.id)
      handleChangeFormData('email', data.email)
      handleNext(REDEFINE_PASSWORD.SEND_CODE)
    },
    onError: () => {
      setError("email", { type: 'custom', message: 'Não foi possível encontrar sua conta'})
    },
  })
  const { control, handleSubmit, setError} = useForm<ForgotPaswordSchema>()

  const onValidateCode = useCallback(
    ({ email }: ForgotPaswordSchema) => {
      validationEmail({
        email,
      })
    },
    [validationEmail],
  )

  return (
    <>
      <S.ResetModal>
        <Stack spacing={3}>
          <Typography variant="h1" fontFamily={'Merriweather'}>
            Esqueceu a senha?
          </Typography>
          <Typography variant="h6">
            Insira o endereço de e-mail que você utilizou para criar sua conta.
            Mandaremos um código para resetar a senha.
          </Typography>

          <TextFieldRHF 
            control={control} 
            name="email" 
            label={"Email"} 
            placeholder="Email" 
            onFocus={() => setEmailError('')} 
          />
      
        <S.footerModal>
          <ButtonPortico onClick={handleSubmit(onValidateCode)}>
              Confirmar
            </ButtonPortico>
        </S.footerModal>
       
        </Stack>
      </S.ResetModal>
    </>
  )
}
