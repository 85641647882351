import React, { useState } from "react";

import * as S from "../styles/styles";
import { ButtonPortico, CheckboxRHF, Modal, TextFieldRHF } from "components";
import { Button, Stack, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { usePostChangePassword, usePostCredential } from "domains";
import {
  NewPasswordSchema,
  newPasswordSchema,
} from "modules/auth/ChangePassword/components/NewPassword/newPasswordSchema";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { usePutChangePassword } from "domains/User/useCases/usePutChangePassword";
import { authStorage } from "services/auth/authStorage";

interface IModalProps {
  isOpen: boolean;
  onClose: () => void;
  isCredendial: any;
}

export const ModalChangePassword = ({
  isOpen,
  onClose,
  isCredendial,
}: IModalProps) => {
  const [emailPasswordError, setEmailPasswordError] = useState<String | null>();

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isValid, isSubmitted },
  } = useForm<NewPasswordSchema>({
    resolver: zodResolver(newPasswordSchema),
    defaultValues: {
      password: "",
      confirmationPassword: "",
    },
    mode: "onSubmit",
    reValidateMode: "onChange",
  });

  // const navigate = useNavigate()
  const { userId } = useParams();

  const { putChangePassword } = usePutChangePassword(isCredendial?.id, {
    onSuccess: () => {
      onClose(), setValue("password", ""), setValue("confirmationPassword", "");
    },
    onError: () => {
      setEmailPasswordError("Email ou senha incorretos");
    },
  });

  const { postCredential } = usePostCredential({
    onSuccess: () => onClose(),
    onError: () => {
      setEmailPasswordError("Email ou senha incorretos");
    },
  });

  const onChangePassword = ({
    password,
    changePasswordNextLogin,
  }: NewPasswordSchema) => {
    if (isCredendial) {
      putChangePassword({
        userId,
        password,
        changePasswordNextLogin,
      });
    } else {
      postCredential({
        userId,
        password,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <S.ResetModal>
        <Stack spacing={3}>
          <Typography
            variant="h1"
            fontFamily={"Merriweather"}
            color={"#161F28"}
          >
            Redefinir senha
          </Typography>
          <Typography variant="h6">
            A senha deve ter 6 caracteres, uma letra maiúscula, uma letra
            minúscula e um caractere especial.
          </Typography>

          <TextFieldRHF
            required
            control={control}
            type="password"
            name="password"
            placeholder="******"
            error={!isValid && isSubmitted}
            helperText={""}
            label={"Nova Senha"}
            rules={{ required: '' }}
            style={{ marginBottom: "24px", marginTop: "10px !important" }}
          />
          
          <TextFieldRHF
            required
            control={control}
            placeholder="******"
            type="password"
            name="confirmationPassword"
            label={"Confirmar Nova Senha"}
            rules={{ required: 'Campos obrigatórios' }}
            error={!isValid && isSubmitted}
            helperText={""}
          />
    
          {!isValid && (
            <p
              style={{
                color: "#FF4B38",
                fontSize: "12px",
                margin: "10px 0 0 16px",
              }}
            >
              {errors.password?.message || errors.confirmationPassword?.message}
            </p>
          )}
          <CheckboxRHF
            control={control}
            name="changePasswordNextLogin"
            label="Forçar nova senha"
          />
          <S.footerModal>
            <ButtonPortico onClick={handleSubmit(onChangePassword)}>
              Alterar senha
            </ButtonPortico>
          </S.footerModal>
        </Stack>
      </S.ResetModal>
    </Modal>
  );
};
