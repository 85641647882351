import styled, {css} from 'styled-components'

type TVariant = {
  variant?: 'outlined' | 'filled'
}

export const WrapperButton = styled.button<TVariant>`
  ${(props) => css`
    border: 1px solid ${props.variant === 'outlined' ? '#bec9d2' : '#161F28'};
    background-color: ${props.variant === 'outlined'
      ? 'transparent'
      : '#161F28'};
    color: ${props.variant === 'outlined' ? '#161f28' : '#F9F9F9'};

    padding: 12px 24px;
    display: flex;
    gap: 8px;
    justify-content: center;
    align-content: center;

    font-size: 16px;
    font-weight: 500;
    font-family: Roboto;

    white-space: nowrap;
    cursor: pointer;

    line-height: 20px;
    height: 44px;

    &:active {
      background-color: ${props.variant === 'outlined'
        ? '#bec9d2'
        : '#59636B'} !important;
    }

    &:hover {
      border: ${props.variant === 'outlined' ? '1px solid #c8d0d9' : ''};
      background-color: ${props.variant === 'outlined' ? '#DFE4E9' : '#3C454D'};
      box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.16);
    }
  `}
`
