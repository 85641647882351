import {useMutation} from 'react-query'
import {MutationOptions} from 'infra'

import {userService} from '../userService'
import {IValidationEmailAPI} from '../userTypes'

interface Variables {
  email: string
}

export function useGetValidationEmail(
  options?: MutationOptions<IValidationEmailAPI>,
) {
  const mutation = useMutation<IValidationEmailAPI, Error, Variables>({
    mutationFn: (variables: Variables) =>
      userService.getValidationEmail(variables),
    retry: false,
    onError: (error) => {
      if (options?.onError) {
        options.onError(error.message)
      }
    },
    onSuccess: (authCredentials) => {
      if (options?.onSuccess) {
        options.onSuccess(authCredentials)
      }
    },
  })

  return {
    isLoading: mutation.isLoading,
    validationEmail: (variables: Variables) => mutation.mutate(variables),
    isSuccess: mutation.isSuccess,
    isError: mutation.isError,
  }
}
