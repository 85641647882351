import { ThemeProvider as MuiThemeProvider, CssBaseline } from '@mui/material';
import { theme } from '@intraversa-lab/styleguide';
import App from './App';


export default function Root(props) {
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <App {...props} />
    </MuiThemeProvider>
  );
}
