import React, { useEffect, useState } from 'react'

import { Button } from '@intraversa-lab/styleguide'
import FilterListIcon from '@mui/icons-material/FilterList'
import SwapVertIcon from '@mui/icons-material/SwapVert'

import * as S from './styles'
import { IconClose, IconFilter, IconOrdernar } from 'components'

interface IFilterProps {
  filterBy: (v: string) => void
  orderBy: (v: string) => void
}

export const FilterHome = ({ filterBy, orderBy }: IFilterProps) => {
  const [showDropFilter, setShowDropFilter] = useState(false)
  const [showDropOrdernar, setShowDropOrdenar] = useState(false)

  const [filtrarPor, setFiltrarPor] = useState(null)
  const [ordenarPor, setOrdernarPor] = useState(null)

  filterBy(filtrarPor !== null ? filtrarPor : null)
  orderBy(ordenarPor)

  function resetFilterTo() {
    setFiltrarPor(null)
    setShowDropFilter(false)
  }

  function resetFilterOrder() {
    setOrdernarPor('')
    setShowDropOrdenar(false)
  }

  console.log('showDorpFilter :', showDropFilter)

  return (
    <S.Wrapper>
      <S.ContentButtonFilter>
        <S.ButtonFilter isFilter={!!filtrarPor || showDropFilter}>
          <div onClick={() => setShowDropFilter(!showDropFilter)}>
            <IconFilter />
            Filtrar por
          </div>

          {!!filtrarPor && (
            <div className="icon-close-filter" onClick={resetFilterTo}>
              <IconClose />
            </div>
          )}
        </S.ButtonFilter>

        {showDropFilter && (
          <>
            <div className="drop-filter">
              <span>Filtros</span>
              <S.ContentFilteButton>
                <label>
                  Ativo
                  <input
                    type="radio"
                    id="ativo"
                    name="filtro"
                    value={'ativo'}
                    checked={filtrarPor === 'ativo'}
                    onChange={(e) => setFiltrarPor(e.target.value)}
                  />
                </label>

                <label>
                  Inativo
                  <input
                    type="radio"
                    id="inativo"
                    name="filtro"
                    value={'inativo'}
                    checked={filtrarPor === 'inativo'}
                    onChange={(e) => setFiltrarPor(e.target.value)}
                  />
                </label>
              </S.ContentFilteButton>
            </div>

            <div
              onClick={() => setShowDropFilter(!showDropFilter)}
              className="overlay-drop-filter"
            ></div>
          </>
        )}
      </S.ContentButtonFilter>

      <S.ContentButtonOrdenar>
        <S.ButtonFilter
          isFilter={!!ordenarPor || showDropOrdernar}>

          <div onClick={() => setShowDropOrdenar(!showDropOrdernar)}>
            <IconOrdernar />
            Ordernar por
          </div>

          {!!ordenarPor && (
            <div onClick={resetFilterOrder}>
              <IconClose />
            </div>
          )}
        </S.ButtonFilter>

        {showDropOrdernar && (
          <>
            <div className="drop-order">
              <h4>Ordenar</h4>

              <div className="category-order">
                {/* <div>
                  <p>Criado em</p>
                </div> */}

                <label>
                  Criado em
                  <input
                    type="radio"
                    id="criado_mais_recente"
                    name="criadoEm"
                    value={'created_at'}
                    checked={ordenarPor === 'created_at'}
                    onChange={(e) => setOrdernarPor('created_at')}
                  />
                </label>

                <label>
                  Última atualização
                  <input
                    type="radio"
                    id="criado_mais_antigos"
                    name="criadoEm"
                    value={'updated_at'}
                    checked={ordenarPor === 'updated_at'}
                    onChange={(e) => setOrdernarPor('updated_at')}
                  />
                </label>
              </div>

              {/* <div className="category-order">
                <div>
                  <p>Última atualização</p>
                </div>

                <label htmlFor="ultima_atualizacao_mais_recente">
                  Mais recentes
                  <input
                    type="radio"
                    id="ultima_atualizacao_mais_recente"
                    value={'atualizado_mais_recente'}
                    name="ultimaAtualizacao"
                    checked={atualizadoEm === 'atualizado_mais_recente'}
                    onChange={(e) => setAtualizadoEm('atualizado_mais_recente')}
                  />
                </label>

                <label htmlFor="ultima_atualizacao_mais_antigo">
                  Mais antigos
                  <input
                    type="radio"
                    id="ultima_atualizacao_mais_antigo"
                    value={'atualizado_mais_antigos'}
                    name="ultimaAtualizacao"
                    checked={atualizadoEm === 'atualizado_mais_antigos'}
                    onChange={(e) => setAtualizadoEm('atualizado_mais_antigos')}
                  />
                </label>
              </div> */}
            </div>

            <div
              onClick={() => setShowDropOrdenar(!showDropOrdernar)}
              className="overlay-drop-order"
            ></div>
          </>
        )}
      </S.ContentButtonOrdenar>
    </S.Wrapper>
  )
}
