import { InputHTMLAttributes } from 'react'


import * as S from './style'
import { IconSearch } from 'components/InputSearch'

export const IconClose = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
     <path d="M1.1875 11.6875L0.3125 10.8125L5.125 6L0.3125 1.1875L1.1875 0.3125L6 5.125L10.8125 0.3125L11.6875 1.1875L6.875 6L11.6875 10.8125L10.8125 11.6875L6 6.875L1.1875 11.6875Z" fill="#75808A"/>
    </svg>


  )
}

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  onClose: () => void
  placeholder: string
  value?: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export const SearchBarMobile = ({
  onClose,
  placeholder,
  value,
  onChange,
}: IProps) => {
  return (
    <S.Wrapper>
      <IconSearch />
      <input
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e)}
      />

      <S.BtnClose 
        style={{
          marginTop: '5px'
        }}
        onClick={() => onClose()}>
        <IconClose/>
      </S.BtnClose>
    </S.Wrapper>
  )
}
