import * as S from './styles'

interface IInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  isError?: boolean
}

export const InputText: React.FC<IInputProps> = ({isError, ...rest}) => {
  return (
    <S.WrapperInput isError={isError}>
      <input type="text" {...rest} />
      {isError && <span>{isError}</span>}
    </S.WrapperInput>
  )
}
