export function hideEmail(email: string) {
  const partes = email.split('@')

  if (partes.length !== 2) {
    return email
  }

  const name = partes[0]
  const domain = partes[1].split('.')[0]

  if (name.length < 2 || domain.length < 2) {
    return email
  }

  const nameHide = name.charAt(0) + '*'.repeat(name.length - 1)
  const domainHide = domain.charAt(0) + '*'.repeat(domain.length - 1)

  return (
    nameHide + '@' + domainHide + '.' + partes[1].split('.').slice(1).join('.')
  )
}
