import { useCallback, useState } from 'react'

import { IChangePasswordCodeRequest } from 'domains'
import { useNavigate } from 'react-router-dom'
import { ForgotPassword } from '../components/ForgotPassword'
import { VerificationCode } from '../components/VerificationCode'
import { NewPassword } from '../components/NewPassword'
import { ConfirmPassword } from '../components/ConfirmPassword'


export enum REDEFINE_PASSWORD {
  VALIDATE_EMAIL,
  SEND_CODE,
  REDEFINE_PASSWORD,
  CONFIRM_PASSWORD,

}

interface FormData extends IChangePasswordCodeRequest {
  email: string
}

export interface IChangePasswordStepsProps {
  handleNext: (value : REDEFINE_PASSWORD) => void
  handleChangeFormData: (name: keyof FormData, newValue: string) => void
  formDataChangePassword: FormData
  closeModal: () => void
}

export const ChangePassword = ({resetModal}) => {
  const [activedStep, setActivedStep] = useState(
    REDEFINE_PASSWORD.REDEFINE_PASSWORD,
  )
  const [formDataChangePassword, setFormDataChangePasword] = useState<FormData>(
    {
      codeId: '',
      password: '',
      userId: '',
      email: '',
    },
  )

  const handleNext = (value) => {
    setActivedStep(value)
  }

  const closeModal = () => {
    resetModal()
  }
  

  const handleChangeFormData = useCallback(
    (name: keyof FormData, newValue: string) => {
      setFormDataChangePasword((old) => ({
        ...old,
        [name]: newValue,
      }))
    },
    [],
  )

  const steps = (
    props: IChangePasswordStepsProps,
  ): { [key in REDEFINE_PASSWORD]: JSX.Element } => ({
    [REDEFINE_PASSWORD.VALIDATE_EMAIL]: <ForgotPassword {...props} />,
    [REDEFINE_PASSWORD.SEND_CODE]: <VerificationCode {...props} />,
    [REDEFINE_PASSWORD.REDEFINE_PASSWORD]: <NewPassword {...props} />,
    [REDEFINE_PASSWORD.CONFIRM_PASSWORD]: <ConfirmPassword {...props} />,

  })

  return (
    <>
      {
        steps({
          handleNext,
          handleChangeFormData,
          formDataChangePassword,
          closeModal
        })[activedStep]
      }
    </>
  )
}
