import styled from "styled-components";

type Input = {
  error: boolean
}


export const ContentInput = styled.div``


export const Wrapper = styled.div<Input>`
  width: 100%;
  height: 48px;
  background-color:  ${(props) => props.error ? "#F9F9F9" : "#EFF2F4 "};;
  border-radius: 16px;
  display: flex;
  align-items: center;
  border: 1px solid transparent; 
  padding-right: 20px;
  

  &[data-status="true"] {
    border: 1px solid #FF4B38;
    color: #FF4B38;

    &:focus-within {
      border: 1px solid #FF4B38;
    }

    &:hover {
      border: 1px solid #FF4B38;
    }

    input {
      color: #FF4B38;
    }
  }

  input {
    background-color: transparent;
    width: 100%;
    height: 100%;
    border: none;
    padding: 12px 0px 12px 24px;
    font-size: 16px;
    color: #161F28;
    outline: none;

    &::placeholder {
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      color: #929EA8;
      letter-spacing: 0.08px;
    }
  }

  &:focus-within {
    background-color: #F9F9F9;
    border: 1px solid #161F28;
  }

  &:hover {
    border: 1px solid #161F28;
    background-color: ##F9F9F9;
  }

  img {
    cursor: pointer;
  }
`

export const Error = styled.span`
  margin-top: 10px;
  margin-left: 16px;
  color:  #FF4B38;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; 
  letter-spacing: 0.06px;
`