


export const IconFilter = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill={'none'} xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_2184_3325" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
        <rect width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_2184_3325)">
        <path d="M8.33333 15V13.75H11.6667V15H8.33333ZM5 10.625V9.375H15V10.625H5ZM2.5 6.25V5H17.5V6.25H2.5Z" fill={'161F28'} />
        <path d="M8.33333 15V13.75H11.6667V15H8.33333ZM5 10.625V9.375H15V10.625H5ZM2.5 6.25V5H17.5V6.25H2.5Z" fill="black" />
        <path d="M8.33333 15V13.75H11.6667V15H8.33333ZM5 10.625V9.375H15V10.625H5ZM2.5 6.25V5H17.5V6.25H2.5Z" fill="black" />
        <path d="M8.33333 15V13.75H11.6667V15H8.33333ZM5 10.625V9.375H15V10.625H5ZM2.5 6.25V5H17.5V6.25H2.5Z" fill="black" />
        <path d="M8.33333 15V13.75H11.6667V15H8.33333ZM5 10.625V9.375H15V10.625H5ZM2.5 6.25V5H17.5V6.25H2.5Z" fill="black" />
      </g>
    </svg>
  )
}