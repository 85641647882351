import { zodResolver } from '@hookform/resolvers/zod'
import { Button, TextField } from '@intraversa-lab/styleguide'
import { Box, Stack, Typography } from '@mui/material'
import { ButtonPortico, InputPortico, TextFieldRHF } from 'components'
import { usePostChangePassword } from 'domains'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { IChangePasswordStepsProps } from '../../ChangePassword'

import { NewPasswordSchema, newPasswordSchema } from './newPasswordSchema'

export const NewPassword = ({
  handleNext,
  formDataChangePassword,
}: IChangePasswordStepsProps) => {
  const navigate = useNavigate()
  const { changePassword, isLoading } = usePostChangePassword({
    onSuccess: () => {
      handleNext()
    },
  })
  const { control, handleSubmit, formState: { errors, isValid, isSubmitted } } = useForm<NewPasswordSchema>({
    resolver: zodResolver(newPasswordSchema),
    defaultValues: {
      password: '',
      confirmationPassword: '',
    },
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  })

  const onChangePassword = ({ password }: NewPasswordSchema) => {
    const { userId, codeId } = formDataChangePassword
    changePassword({
      userId,
      codeId,
      password,
    })
  }

  console.log(errors)

  return (
    <>
      <Box>
        <Stack spacing={3}>
          <Typography
            variant="h1"
            fontFamily={'Merriweather'}
            color={'#161F28'}
            fontSize={'36px'}>
            Criar Nova Senha
          </Typography>

          <Typography
            variant="h6"
            fontFamily={'Roboto'}
            color={'#75808A'}
            style={{ marginTop: '24px' }}>
            A senha deve ter 6 caracteres, uma letra maiúscula, uma letra
            minúscula e um caractere especial.
          </Typography>

          <TextFieldRHF
            required
            control={control}
            type="password"
            name="password"
            error={!isValid && isSubmitted}
            placeholder="Nova Senha"
          />

          <TextFieldRHF
            required
            control={control}
            placeholder="Confirmar Nova Senha"
            type="password"
            error={!isValid && isSubmitted}
            name="confirmationPassword"
          />

          {!isValid && isSubmitted &&(
            <p
              style={{
                color: '#FF4B38',
                fontSize: '12px',
                marginTop: '10px',
              }}>
               {errors.confirmationPassword ? errors.confirmationPassword.message : 'As senhas precisam ser iguais.'}
            </p>
          )}

          <ButtonPortico onClick={handleSubmit(onChangePassword)}>
            Confirmar
          </ButtonPortico>
        </Stack>

        <Box display={'flex'} justifyContent={'center'}>
          <Button
            variant="text"
            arround
            style={{
              marginTop: 48,
              textTransform: 'none',
              padding: '12px 24px',
              fontSize: '16px',
            }}
            sx={{
              '&:hover': {
                backgroundColor: '#E2D9F9 !important',
                color: '#5735B4',
              },
            }}
            onClick={() => navigate('/')}>
            Retornar ao Login
          </Button>
        </Box>
      </Box>
    </>
  )
}
