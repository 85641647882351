import {Auth, SignIn, ChangePassword} from 'modules/auth'

export const authRoutes = [
  {
    path: '/',
    template: Auth,
    subRoutes: [
      {
        path: '',
        component: () => <SignIn />,
        isPrivate: true,
      },
      {
        path: 'forgotPassword',
        component: () => <ChangePassword />,
        isPrivate: true,
      },
    ],
  },
]
