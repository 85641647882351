import { InputHTMLAttributes, useState } from 'react'

import * as S from './styles'

export const IconSearch = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_1276_27575" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
        <rect width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1276_27575)">
        <path d="M16.5833 17.4805L11.1042 12.0013C10.6875 12.3624 10.2017 12.6437 9.64669 12.8451C9.0917 13.0464 8.50114 13.1471 7.875 13.1471C6.37275 13.1471 5.10135 12.6263 4.06081 11.5846C3.02027 10.543 2.5 9.28602 2.5 7.8138C2.5 6.34158 3.02083 5.08464 4.0625 4.04297C5.10417 3.0013 6.36458 2.48047 7.84375 2.48047C9.32292 2.48047 10.5799 3.0013 11.6146 4.04297C12.6493 5.08464 13.1667 6.34262 13.1667 7.81693C13.1667 8.41207 13.0694 8.98741 12.875 9.54297C12.6806 10.0985 12.3889 10.6194 12 11.1055L17.5 16.5638L16.5833 17.4805ZM7.85417 11.8971C8.98264 11.8971 9.94184 11.4978 10.7318 10.6992C11.5217 9.90061 11.9167 8.9388 11.9167 7.8138C11.9167 6.6888 11.5217 5.727 10.7318 4.92839C9.94184 4.12977 8.98264 3.73047 7.85417 3.73047C6.71413 3.73047 5.74508 4.12977 4.94704 4.92839C4.14901 5.727 3.75 6.6888 3.75 7.8138C3.75 8.9388 4.14901 9.90061 4.94704 10.6992C5.74508 11.4978 6.71413 11.8971 7.85417 11.8971Z" fill="#75808A" />
      </g>
    </svg>

  )
}

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  placeholder?: string
  value: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onShowMobile?: () => void
}

export const InputSearch = ({ placeholder, onShowMobile, value, onChange, ...rest }: IProps) => {
  const [mobileMenu, setMobileMenu] = useState(false)
  const [isFocus, setIsFocus] = useState(false)
  // cosnt[(isFocused, SetIsFocused)] = useState(false)

  const handleInputFocus = () => {
    setIsFocus(!isFocus)
  }

  const handleInputBlur = () => {
    setIsFocus(!isFocus)
  }

  return (
    <S.Content {...rest}>
      <S.WrapperInput isFocused={isFocus}>
        <IconSearch />
        <input
          type="text"
          placeholder={placeholder}
          value={value}
          onChange={(e) => onChange(e)}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          {...rest}
        />
      </S.WrapperInput>

      <S.ButonSearch onClick={onShowMobile}>
        <IconSearch />
      </S.ButonSearch>
    </S.Content>
  )
}
