import { COLORS } from '@intraversa-lab/styleguide'
import { Box, Grid, Typography, useMediaQuery } from '@mui/material'
import { makeStyles } from '@mui/styles'


export const IconLogin = () => {
  return (
    <svg width="241" height="400" viewBox="0 0 241 400" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_493_8639)">
        <path d="M160.5 89L240.5 89V0L160.5 0V89Z" fill="#BDADE9" />
        <path d="M120.5 266V355H240.5V266H120.5Z" fill="#E5EF7B" />
        <path d="M120.5 400V355H0.500008V400H120.5Z" fill="white" />
        <path d="M120.5 44V0L0.5 0V44L120.5 44Z" fill="#DFE4E9" />
        <path d="M40.5 134L160.5 134V44L40.5 44L40.5 134Z" fill="#FFFFF4" />
        <path d="M80.5 134V89H0.5V134H80.5Z" fill="#E5EF7B" />
        <path d="M200.5 222.001H160.5V400.001H200.5V222.001Z" fill="#BDADE9" />
        <path d="M200.5 222.001V178L80.5 178.001V222.001H200.5Z" fill="#BDADE9" />
        <path d="M80.5 178.001H40.5V400.001H80.5V178.001Z" fill="#BDADE9" />
      </g>
      <defs>
        <clipPath id="clip0_493_8639">
          <rect width="240" height="400" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>

  )
}

export const IconLogo2 = () => {
  return (
    <svg
      width="56"
      height="64"
      viewBox="0 0 56 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_3547_5528)">
        <path
          d="M4.45465 35.4683C4.45465 32.1146 5.83545 30.7658 7.49612 30.7658C9.0712 30.7658 10.5376 32.1278 10.5376 35.4683V38.0026H14.9922V35.903C14.9945 28.4764 12.0618 26 7.66265 26H7.65802C3.6752 26 0 28.0654 0 35.9003V38H4.45465V35.4656V35.4683Z"
          fill="#F9F9F9"
        />
        <path
          d="M24.9726 35.4676C24.9726 32.1271 26.3858 30.7651 28.0141 30.7651C29.6423 30.7651 31.0555 32.1271 31.0555 35.4676V38.002H35.5102V35.9023C35.5102 28.4784 32.5774 26.002 28.176 26.002C23.7745 26.002 20.5156 28.4151 20.5156 35.9023V38.002H24.9703V35.4676H24.9726Z"
          fill="#F9F9F9"
        />
        <path
          d="M45.4882 35.4314C45.4882 33.4793 46.0618 30.7658 48.5297 30.7658C50.1048 30.7658 51.5712 32.0909 51.5712 35.4314C51.5712 35.4314 51.5712 36.5458 51.5712 38H55.998L55.9865 35.5842C55.9865 28.2973 52.9335 26 48.6916 26C44.8915 26 41.0312 28.2261 41.0312 35.8634V38H45.4859V35.4314H45.4882Z"
          fill="#F9F9F9"
        />
      </g>
      <defs>
        <clipPath id="clip0_3547_5528">
          <rect
            width="56"
            height="12"
            fill="white"
            transform="translate(0 26)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

const useClasses = makeStyles({
  container: {
    width: '100vw',
    height: '100vh',
    backgroundColor: COLORS.shade[5],
  },
  gridIcon: {
    display: 'grid',
    flexDirection: 'column',
    padding: '0 32px',
    background: COLORS.shade[10],
    width: '100%',
    height: '100%',
  },
  contentGridIcon: {
    maxWidth: 470,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flex: 1,
  },
  logo: {
    marginBottom: 48,
  },
  center: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  titleGridIcon: {
    fontFamily: 'Roboto',
    fontSize: '28px !important',
    fontWeight: 600,
    textAlign: 'left',
    marginTop: '48px !important',
  },
  descriptionGridIcon: {
    fontFamily: 'Roboto',
    fontSize: '18px !important',
    fontWeight: 600,
    textAlign: 'left',
  },
  gridForm: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  containerForm: {
    maxWidth: 530,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: 20,
  },
})

export const TemplateAuth = ({ children }: { children: React.ReactNode }) => {
  const classes = useClasses()
  const isSmallScreen = useMediaQuery('(max-width:900px)')

  return (
    <Grid container className={classes.container} alignItems="center">
      {!isSmallScreen && (
        <Grid item xs={0} sm={0} md={6} className={classes.gridIcon}>
          <Box className={classes.logo}>
            {/* <LogoIntraversa /> */}
            <IconLogo2 />
          </Box>

          <Box className={classes.center}>
            <Box className={classes.contentGridIcon}>
              <Box className={classes.image}>
                <IconLogin />
              </Box>
              <Typography className={classes.titleGridIcon} color={'#fff'}>
                Todas as suas integrações em um único espaço de trabalho
              </Typography>
              <Typography
                className={classes.descriptionGridIcon}
                color={'#fff'}>
                Construa um ambiente conectado e automatizado.
              </Typography>
            </Box>
          </Box>
        </Grid>
      )}

      <Grid item xs={12} sm={12} md={6} className={classes.gridForm}>
        <Box className={classes.containerForm}>{children}</Box>
      </Grid>
    </Grid>
  )
}
