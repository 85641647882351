import {styled} from 'styled-components'

export const Container = styled.div`
`

export const WrapperHeader = styled.div`
margin-top: 32px;
padding: 0px 32px 32px 32px;
border-bottom: 1px solid #DFE4E9;
@media (max-width: 922px) {
  padding: 0px 16px 24px 16px;
  
}

  h1 {
    margin: 0;
  }

  p {
    margin-bottom: 0;
    color: #75808a;
    font-weight: 500;
  }

`
export const ContentDescription = styled.div`
  padding: 32px 32px;
  border-bottom: 1px solid #dfe4e9;

  .wrapper {
    display: flex;
    align-items: center;
    gap: 2rem;

    .info {
      max-width: 320px;
      width: 100%;
      display: flex;
      flex-direction: column;

      span:first-child {
        color: #161f28;
        font-weight: 500;
        font-size: 1rem;
      }

      span:nth-child(2) {
        font-family: Roboto;
        color: #75808a;
        font-weight: 400;
        color: #75808a;
      }
    }

    @media (max-width: 850px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      margin-top: 32px;
      margin-bottom: 16px;
      margin-left: 0;
      margin-right: 0;
    }
  }

  .content-button {
    display: flex;
    gap: 2rem;

    button {
      /* flex: 1; */
    }
  }

  @media (max-width: 922px) {
    .content-button {
      gap: 1rem;
      display: flex;
      flex-direction: column;
      align-items: normal;
      width: 100%;
    }
  }

  @media (max-width: 850px) {
    padding: 0px 16px;

  }

  button {
    text-transform: none !important;
  }
`;

export const ContenetBtn = styled.div`
  button {
    height: 44px;
    width: 179px;
    padding: 12px 16px 12px 24px;
    font-size: 16px;
    text-transform: math-auto !important;
    border: 1px solid #bec9d2;
    .css-1gnd1fd-MuiButton-endIcon > *:nth-of-type(1) {
      font-size: 18px;
    }

    &:active {
      background-color: #bec9d2 !important;
      border: 1px solid #aebcc7 !important;
      color: #3c454d;
    }

    &:hover {
      background-color: '#DFE4E9 !important';
      border: '1px solid #C8D0D9 !important';
      color: '#000';
    }
  }

  .newProfile {
    color: #f9f9f9;
    height: 44px;
    border: none !important;
  }

  .newProfile:active {
    background-color: #59636b !important;
    color: #c8d0d9 !important;
    border: none !important;
  }
`

export const ContenetBtnGroup = styled.div`
  display: flex;
  gap: 32px;
  margin-top: 20px;
  margin-bottom: 20px;

  padding: 0px 32px 0px 384px;

  button {
    height: 44px;
    width: 320px;
  }

  @media (max-width: 922px) {
    padding: 0px 16px;
    gap: 16px;
  }

  
`

export const ContainerSendCode = styled.div`

  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: column;

  .text-code{
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    align-items: center;
  }

  @media(max-width: 850px){
    flex-direction: column;
    .text-code{

      display: contents;

    }
  }

`

export const footerModal = styled.div`
width: 100%;
display: grid;

@media (max-width: 922px) {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0px 30px 32px 0px;
}

button{
  line-height: 20px;
}
`;

export const ButtonForgotPassword = styled.button`

color: #5735B4;
text-align: center;
font-family: Roboto;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 20px;
letter-spacing: 0.016px;

@media (min-width: 922px) {

    text-transform: none;
    border-radius: 40px;
    display: flex;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;

    &:hover {
      background: #E2D9F9;
      color: #5735B4;
    }

    
    &:active {
      background: #C5B3F3;
      color: #412887;
    }
    
    
}
`

export const ResetModal = styled.div`
  h1 {
    font-size: 36px;
    color: #161f28;
  }

  p {
    margin-top: 0;
    font-size: 16px;
    color: #75808a;
    font-weight: 400;
  }

  label{
    margin-top: 8px !important;
  }

  .Mui-error{
    font-size: 12px;
    margin-top: 8px;
  }

  .MuiFormLabel-root{
    display: none
  }

  .Mui-error{
    margin-top: 0;
  }

  .css-19qh8xo-MuiInputBase-input-MuiOutlinedInput-input:invalid{
    padding: 0 0 0 24px;
  }

  @media (max-width: 922px) {
    gap: 8px;
    .MuiFormLabel-root{
      display: block;
      padding: 0 16px;

    }

  }

`;

