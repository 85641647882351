import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  background-color: #eff2f4;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 12px;

  input[type='text'] {
    width: 100%;
    background-color: transparent;
    border: none;
    outline: none;

    &::placeholder {
      font-weight: 500;
    }
  }
`

export const BtnClose = styled.div`
  cursor: pointer;
  height: 19px;
`
