import {toast} from '@intraversa-lab/styleguide'

import {commonApi} from './commonApi'
import {ICommonEnvironment, ICommonStatus} from './commonTypes'

async function getStatus(): Promise<ICommonStatus[]> {
  try {
    const respose = await commonApi.getStatus()

    return respose
  } catch (error) {
    toast({
      type: 'error',
      text: 'Erro ao buscar status.',
      description: 'Tente novamente em instantes.',
    })
    throw new Error('Tente novamente em instantes.')
  }
}

async function getEnvironments(): Promise<ICommonEnvironment[]> {
  try {
    const respose = await commonApi.getEnvironments()

    return respose
  } catch (error) {
    toast({
      type: 'error',
      text: 'Erro ao buscar environments.',
      description: 'Tente novamente em instantes.',
    })
    throw new Error('Tente novamente em instantes.')
  }
}

export const commonServices = {
  getEnvironments,
  getStatus,
}
