import {api} from 'api'
import {endpointsCommon} from 'infra'

import {ICommonEnvironment, ICommonStatus} from './commonTypes'

async function getStatus(): Promise<ICommonStatus[]> {
  const response = await api.get(endpointsCommon.getStatus)
  return response.data
}

async function getEnvironments(): Promise<ICommonEnvironment[]> {
  const response = await api.get(endpointsCommon.getEnv)
  return response.data
}

export const commonApi = {
  getStatus,
  getEnvironments,
}
