import { zodResolver } from '@hookform/resolvers/zod'
import { Box, Stack, Typography } from '@mui/material'
import { ButtonPortico,  TextFieldRHF } from 'components'
import {  usePostCredential } from 'domains'
import { useForm } from 'react-hook-form'


import { IChangePasswordStepsProps, REDEFINE_PASSWORD } from '../../RedefinePassword'
import { confirmPasswordSchema, ConfirmPasswordSchema } from './confirmPasswordSchema'
import { LocalStorage } from 'infra'
import * as S from "../../styles";

export const ConfirmPassword = ({
  handleNext,
  closeModal
}: IChangePasswordStepsProps) => {
  const userLogger =  JSON.parse(window.localStorage.getItem(LocalStorage.USER_LOGGED))
  const { postCredential } = usePostCredential({
    onSuccess: () => {
      handleNext(REDEFINE_PASSWORD.CONFIRM_PASSWORD)
      closeModal();
    }, 
  })
  const { control, handleSubmit, formState: { errors, isValid, isSubmitted } } = useForm<ConfirmPasswordSchema>({
    resolver: zodResolver(confirmPasswordSchema),
    defaultValues: {
      password: '',
      confirmationPassword: '',
    },
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  })

  const onChangePassword = ({ password }: ConfirmPasswordSchema) => {
    postCredential({
      userId: userLogger.credential.userId,
      password,
    })
  }

  return (
    <>
      <S.ResetModal>
        <Stack spacing={3}>
          <Typography
            variant="h1"
            fontFamily={"Merriweather"}
            color={"#161F28"}
            fontSize={"36px"}
          >
            Criar nova senha
          </Typography>

          <Typography
            variant="h6"
            fontFamily={"Roboto"}
            color={"#75808A"}
            style={{ marginTop: "24px" }}
          >
            A senha deve ter 6 caracteres, uma letra maiúscula, uma letra
            minúscula e um caractere especial.
          </Typography>

          <TextFieldRHF
            required
            control={control}
            type="password"
            name="password"
            placeholder="******"
            error={!isValid && isSubmitted}
            helperText={""}
            label={"Nova Senha"}
            rules={{ required: "" }}
            style={{ marginBottom: "24px", marginTop: "10px !important" }}
          />

          <TextFieldRHF
            required
            control={control}
            placeholder="******"
            type="password"
            name="confirmationPassword"
            label={"Confirmar Nova Senha"}
            rules={{ required: "Campos obrigatórios" }}
            error={!isValid && isSubmitted}
            helperText={""}
          />
          {!isValid && isSubmitted && (
            <p
              style={{
                color: "#FF4B38",
                fontSize: "12px",
                marginTop: "10px",
                padding: "0 16px"
              }}
            >
              {errors.confirmationPassword
                ? errors.confirmationPassword.message
                : "As senhas precisam ser iguais."}
            </p>
          )}

          <S.footerModal>
            <ButtonPortico onClick={handleSubmit(onChangePassword)}>
              Alterar senha
            </ButtonPortico>
          </S.footerModal>
        </Stack>
      </S.ResetModal>
    </>
  );
}
