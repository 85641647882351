import styled from '@emotion/styled'

export const Container = styled.div`
  width: 100vw;
  min-height: 100vh;
  background-color: #f9f9f9;
`
export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 32px;
  height: 64px;
  border-bottom: 1px solid #eff2f4;
  @media (max-width: 922px) {
    padding: 0 16px;
  }
  h3 {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    cursor: pointer;
  }

  @media (max-width: 600px) {
    padding: 0 16px;
  }
`
export const Menu = styled.div`
  display: flex;
  align-items: center;

  gap: 40px;

  a {
    padding: 20px 4px;
    text-decoration: none;
    color: #161f28;
    border-bottom: 2px solid transparent;

    &:hover {
      color: #3c454d;
      background-color: #eff2f4;
    }
  }

  a.active {
    color: #6d42e1;
    border-bottom: 2px solid #6d42e1;
  }
`
type TLogootProps = {
  isActive: boolean
}

export const ContentButtonLogoout = styled.div`
  position: relative;

  .drop-logout {
    position: absolute;
    z-index: 3;
    top: 40px;
    right: 0px;
    background-color: #fff;
    border: 1px solid #dfe4e9;
    width: 200px;
    border-radius: 4px;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.16);
    padding: 8px;

    a {
      text-decoration: none;
    }

    div {
      display: flex;
      align-items: center;
      padding: 19px 16px;
      gap: 12px;
      border-radius: 4px;
      cursor: pointer;

      svg,
      img {
        width: 20px;
        height: 20px;
        color: #59636b;
        fill: #59636b;
      }

      span {
        color: #59636b;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
      }

      &:hover {
        background-color: #eff2f4;
        sapn {
          color: #3c454d;
        }
      }
    }
  }

  .orverlay-drop-logout {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }
`

export const ButtonLogout = styled.button<TLogootProps>`
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  padding: 8px;
  border-radius: 16px;

  border: 1px solid ${(props) => (props.isActive ? '#6d42e1' : '#dfe4e9')};
  outline: none;
  background-color: transparent;

  svg,
  img {
    filter: ${(props) =>
    props.isActive
      ? 'brightness(0) saturate(100%) invert(30%) sepia(94%) saturate(3555%) hue-rotate(246deg) brightness(90%) contrast(96%)'
      : ''};
  }

  &:hover {
    background-color: #dfe4e9;
  }
`

export const Body = styled.div`
  @media (max-width: 600px) {
  }
`
