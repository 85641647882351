import {z} from 'zod'

export const newPasswordSchema = z
  .object({
    password: z
      .string({required_error: 'Senha inválida',})
      .min(1, 'Senha inválida')
  })

export type NewPasswordSchema = z.infer<typeof newPasswordSchema>
