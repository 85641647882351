import {passwordRegex} from 'utils/regex'
import {z} from 'zod'

export const confirmPasswordSchema = z
.object({
  password: z.string({
    required_error: '',
  })
    .min(6, 'Senhas inválidas')
    .regex(/[a-z]/, 'Senhas inválidas')
    .regex(/[A-Z]/, 'Senhas inválidas')
    .regex(/\d/, 'Senhas inválidas')
    .regex(
      /[@$!%*?&]/,
      'Senhas inválidas',
    )
    .regex(
      passwordRegex,
      'Senhas inválidas',
    ),
  confirmationPassword: z.string()
    .min(1, 'Campos obrigatórios')
    .min(6, 'Senhas inválidas'),
})
.refine((data) => data.password === data.confirmationPassword, {
  message: 'As senhas precisam ser iguais.',
  path: ['confirmationPassword'],
})
export type ConfirmPasswordSchema = z.infer<typeof confirmPasswordSchema>
