import { useState } from 'react'

import { zodResolver } from '@hookform/resolvers/zod'
import { Button, ButtonPortico } from '@intraversa-lab/styleguide'
import { Box, Stack, Typography } from '@mui/material'
import { TextFieldRHF } from 'components/FormRHF/TextFieldRHF'
import { useAuthSignIn } from 'domains'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { LoginSchema, loginSchema } from './loginSchema'
import { ChangePasswordNextLogin } from './components/ChangePasswordNextLogin'

export const SignIn = () => {
  const navigate = useNavigate();
  const [emailPasswordError, setEmailPasswordError] = useState<String | null>();
  const [changePasswordNextLogin, setChangePasswordNextLogin] =
    useState<boolean>(false);
  const [userId, setUserId] = useState<String>();

  const { control, handleSubmit, getValues } = useForm<LoginSchema>({
    resolver: zodResolver(loginSchema),
    defaultValues: {
      email: "",
      password: "",
      keepLogin: false,
    },
    mode: "onTouched",
    reValidateMode: "onSubmit",
  });
  const { isLoading, signIn } = useAuthSignIn({
    onError: () => {
      setEmailPasswordError("Email ou senha incorretos");
    },
    onSuccess: (data) => {
      if (!data.credential.changePasswordNextLogin) {
        navigate("home");
      } else {
        setUserId(data.credential.userId)
        setChangePasswordNextLogin(true);
      }
    },
  });

  const onSubmit = ({ email, password, keepLogin }: LoginSchema) => {
    signIn({
      email,
      password,
      isKeepLogin: true,
    });
  };

  return (
    <>
      {!changePasswordNextLogin ? (
        <Box>
          <Stack spacing={3}>
            <Typography variant="h1" fontFamily={"Merriweather"}>
              Login
            </Typography>
            <Typography variant="h6">
              Insira seus dados para acessar a plataforma
            </Typography>
            <TextFieldRHF
              required
              control={control}
              name="email"
              placeholder="Email"
              id="EmailLogin"
            />
            <TextFieldRHF
              placeholder="Senha"
              control={control}
              type="password"
              name="password"
              id="SenhaLogin"
            />
            {emailPasswordError && (
              <p
              id="ErrorMessage"  
              style={{
                  color: "#FF4B38",
                  fontSize: "12px",
                  marginTop: "10px",
                }}
              >
                {emailPasswordError}
              </p>
            )}
            {/* <CheckboxRHF
            label="Manter login"
            control={control}
            name="keepLogin"
          /> */}
            <ButtonPortico
              disabled={isLoading}
              onClick={handleSubmit(onSubmit)}
              id="Entrar"
            >
              Entrar
            </ButtonPortico>
          </Stack>

          <Box display={"flex"} justifyContent={"center"}>
            <Button
              id="ForgotPassword"
              variant="text"
              arround
              style={{
                marginTop: 48,
                textTransform: "none",
                padding: "12px 24px",
                fontSize: "16px",
              }}
              sx={{
                "&:hover": {
                  backgroundColor: "#E2D9F9 !important",
                  color: "#5735B4",
                },
              }}
              onClick={() => navigate("forgotPassword")}
            >
              Esqueceu a senha?
            </Button>
          </Box>

          <Box display={"flex"} justifyContent={"center"}>
            <p>v 0.0.1</p>
          </Box>
        </Box>
      ) : (
        <ChangePasswordNextLogin userId={userId} email={getValues().email}/>
      )}
    </>
  );
}
